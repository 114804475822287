export const Cors = {
  namespaced: true,
  state: {
    cors: []
  },
  mutations: {
    SET_CORS(state, cors) {
      state.cors = cors
    }
  },
  actions: {
    setCors({ commit }, cors) {
      commit('SET_CORS', cors)
    }
  }
}
