import axios from "axios";
import store from "@/store";
import moment from "moment";

const apiClient = axios.create({
  baseURL: window.VUE_APP_ENV_DS_URL ? window.VUE_APP_ENV_DS_URL : "https://acc-ds.t1t.io",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

function getCount(organisations, labels) {
  let params;
  if (organisations.length > 0) {
    params = organisations.map((v) => {
      return `org=${v}&`;
    });
  }
  if (labels.length > 0) {
    if (params) {
      let labelParams = labels.map((v) => {
        return `label=${v}&`;
      });
      params = params.concat(labelParams);
    } else {
      params = labels.map((v) => {
        return `label=${v}&`;
      });
    }
  }
  if (params) {
    return apiClient.get(`/v3_5/dashboard/devices/count?${params.join("")}`, {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    });
  } else {
    return apiClient.get("/v3_5/dashboard/devices/count", {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    });
  }
}

function getDeviceBreakdown(organisations, labels) {
  let params;
  if (organisations.length > 0) {
    params = organisations.map((v) => {
      return `org=${v}&`;
    });
  }
  if (labels.length > 0) {
    if (params) {
      let labelParams = labels.map((v) => {
        return `label=${v}&`;
      });
      params = params.concat(labelParams);
    } else {
      params = labels.map((v) => {
        return `label=${v}&`;
      });
    }
  }
  if (params) {
    return apiClient.get(`/v3_5/dashboard/devices/breakdown?${params.join("")}`, {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    });
  } else {
    return apiClient.get("/v3_5/dashboard/devices/breakdown", {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    });
  }
}

function getDevice(deviceID) {
  return apiClient.get(`/v3_5/devices/${deviceID}`, {
    headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
  });
}

function getTransactionReport(organizations, labels, periodStart, periodEnd) {
  var data = {
    filter: {
      from: 0,
      size: 0,
      period: {
        start: periodStart
          ? moment(periodStart)
              .utc()
              .format()
          : null,
        end: periodEnd
          ? moment(periodEnd)
              .utc()
              .format()
          : null,
      },
    },
    breakDownBy: "USE_CASE",
  };
  if (organizations && organizations.length > 0) {
    data.filter.orgs = organizations;
  }
  if (labels && labels.length > 0) {
    data.filter.labels = labels;
  }
  return apiClient.post("/v3_5/transactions/report", data, {
    headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
  });
}

function getDnsRebinds(periodStart, periodEnd) {
  return apiClient.get(
    `/v3_5/dashboard/devices/dns-rebind?${
      periodStart ? `periodStart=${new Date(periodStart).toISOString()}&` : ""
    }${periodEnd ? `periodEnd=${new Date(periodEnd).toISOString()}&` : ""}`,
    {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    }
  );
}

function getLogServiceStatus() {
  return apiClient.get(`/logs/v3_5/system/status`, {
    headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
  });
}

function getLogs(deviceID, prefix) {
  return apiClient.get(`/logs/v3_5/devices/${deviceID}${prefix ? `?prefix=${prefix}` : ""}`, {
    headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
  });
}

function downloadLogs(deviceID, fileName, timestamp, inline) {
  return apiClient.get(
    `/logs/v3_5/devices/${deviceID}/${fileName}?timestamp=${timestamp}&inline=${inline}`,
    {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
    }
  );
}

function downloadTimestamp(deviceID, timestamp) {
  return apiClient.post(
    `/logs/v3_5/devices/${deviceID}/zip?timestamp=${timestamp}`,
    {},
    {
      headers: { Authorization: `Bearer ${store.state.user.accessToken}` },
      responseType: "blob",
    }
  );
}

export default {
  getCount,
  getDeviceBreakdown,
  getDevice,
  getTransactionReport,
  getDnsRebinds,
  getLogServiceStatus,
  getLogs,
  downloadLogs,
  downloadTimestamp,
};
