<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  props: {
    keycloak: {
      type: Object
    }
  },
  data() {
    return {
      activeLang: null
    }
  },
  created() {
    this.activeLang = this.$i18n.locale
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang
      window.VUE_APP_ENV_DEFAULT_LANGUAGE = lang
    }
  }
}
</script>
