<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex justify-between p-4">
        <div class="font-medium text-3xl">
          <b>{{ $t("administration.Manage CORS") }}</b>
        </div>
        <div
          class="export"
          @click="exportCSV()"
        >
          <i
            class="fas fa-file-csv fa-2x"
            color="grey"
          />
        </div>
      </div>
      <div class="col-span-12 px-10 mt-10">
        <div class="overflow-x-auto">
          <table class="table">
            <colgroup>
              <col
                span="1"
                style="width: 50%;"
              >
              <!--              <col-->
              <!--                span="1"-->
              <!--                style="width: 25%;"-->
              <!--              >-->
              <col
                span="1"
                style="width: 50%;"
              >
            </colgroup>
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  {{ $t("administration.Domains") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap" />
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(domain, index) in dataToDisplay"
                :key="index"
              >
                <td class="border-b whitespace-nowrap">
                  {{ domainName(domain.origin) }}
                </td>
                <!--                <td class="border-b whitespace-nowrap">-->
                <!--                  <a :href="'https://www.' + domainName(domain.origin)"><i class="fas fa-external-link-alt" /></a>-->
                <!--                </td>-->
                <td class="border-b whitespace-nowrap text-right">
                  <span
                    class="remove-domain"
                    @click="deleteConfirmation(domain.id)"
                  ><i
                    class="fas fa-times"
                    color="red"
                  /></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 mt-14 px-10 ml-4">
        <div class="col-span-2 flex">
          <select
            v-model="numberPerPage"
            class="number-per-page"
            aria-label="Default select example"
            @change="currentPage > totalPages ? currentPage = totalPages : ''"
          >
            <option value="5">
              5
            </option>
            <option value="10">
              10
            </option>
            <option value="15">
              15
            </option>
            <option value="20">
              20
            </option>
            <option value="50">
              50
            </option>
            <option value="100">
              100
            </option>
          </select>
          <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
        </div>
        <div class="col-span-5 text-right">
          <span
            class="pagination-most-left"
            @click="currentPage = 1"
          ><i class="fas fa-angle-double-left" /></span>
          <span
            class="pagination-left"
            @click="currentPage > 1 ? currentPage -= 1 : ''"
          ><i class="fas fa-angle-left" /></span>
          <span class="current-page">{{ currentPage }} of {{ totalPages }}</span>
          <span
            class="pagination-right"
            @click="currentPage < totalPages ? currentPage += 1 : ''"
          ><i class="fas fa-angle-right" /></span>
          <span
            class="pagination-most-right"
            @click="currentPage = totalPages"
          ><i class="fas fa-angle-double-right" /></span>
        </div>
      </div>
      <div class="col-span-12 text-right my-5 pr-10">
        <button
          class="btn btn-block remove-button text-white"
          @click="deleteConfirmation('all')"
        >
          {{ $t("administration.Remove all") }}
        </button>
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#add-domain-modal"
          class="btn btn-large add-button"
        >{{ $t("administration.Add Domain") }}</a>
      </div>
      <div
        id="add-domain-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <a
              data-dismiss="modal"
              href="javascript:;"
            >
              <XIcon class="w-8 h-8 text-gray-500" />
            </a>
            <div class="modal-body p-10">
              <div class="pl-5">
                <b>{{ $t("administration.Add Domain") }}</b>
              </div>
              <div class="p-5 text-center">
                <input
                  v-model="newDomain"
                  type="text"
                  class="form-input intro-x login__input form-control border-gray-300"
                >
              </div>
              <div class="px-5 pt-4 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn add-button"
                  @click="addDomain"
                >
                  {{ $t("administration.Add") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
                <div class="text-3xl mt-5">
                  {{ $t("administration.Are you sure?") }}
                </div>
                <div class="text-gray-600 mt-2">
                  {{ $t("administration.Really delete") }} <span v-if="idToRemove === 'all'"><b>{{ $t("administration.all") }}</b> {{ $t("administration.the domains") }}</span> <span v-if="idToRemove !== 'all'">{{ $t("administration.this domain") }}</span>? <br>{{ $t("administration.Cannot be undone") }}
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="btn btn-outline-secondary w-24 mr-1"
                  @click="indexToRemove = null"
                >
                  {{ $t("administration.Cancel") }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger w-24"
                  data-dismiss="modal"
                  @click="removeDomain(indexToRemove)"
                >
                  {{ $t("administration.Delete") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdministrationService from '@/services/AdministrationService'
import Toastify from 'toastify-js'
import store from '@/store/index.js'

export default {
  name: 'ManageCors',
  beforeRouteEnter(routeTo, routeFrom, next) {
    if(store.state.user.roles.includes('SysAdmin')) {
      next(true)
    } else {
      next({ name: 'summary' })
    }
  },
  data() {
    return {
      currentPage: 1,
      numberPerPage: 5,
      idToRemove: null,
      newDomain: null,
      domains: []
    }
  },
  computed: {
    dataToDisplay() {
      return this.domains.slice((this.currentPage * this.numberPerPage - this.numberPerPage), this.currentPage * this.numberPerPage)
    },
    totalPages() {
      if (this.domains) {
        return Math.ceil(this.domains.length / this.numberPerPage)
      } else {
        return Math.ceil(this.domains.length / this.numberPerPage)
      }
    }
  },
  created() {
    this.fetchCors()
  },
  methods: {
    fetchCors() {
      AdministrationService.getCors()
        .then(response => {
          this.$store.dispatch('cors/setCors', response.data.data.allowedOrigins)
          this.domains = this.$store.state.cors.cors
        })
    },
    removeDomain() {
      if (this.idToRemove === 'all') {
        this.domains.forEach(domain => {
          AdministrationService.removeCorsDomain(domain.id)
            .then(response => {
              this.idToRemove = null
              this.fetchCors()
              Toastify({
                text: 'Domains successfully removed',
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true,
                className: 'toastify-content'
              }).showToast()
            })
            .catch(err => {
              let error = ''
              if (err.response.data.description){
                error = err.response.data.description
              }
              Toastify({
                text: 'Domains could not be removed' + error,
                duration: 3000,
                newWindow: true,
                close: true,
                gravity: 'top',
                position: 'right',
                stopOnFocus: true,
                className: 'toastify-content'
              }).showToast()
            })
        })
      } else {
        AdministrationService.removeCorsDomain(this.idToRemove)
          .then(response => {
            this.idToRemove = null
            this.fetchCors()
            Toastify({
              text: 'Domain successfully removed',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          })
          .catch(err => {
            let error = ''
            if (err.response.data.description){
              error = err.response.data.description
            }
            Toastify({
              text: 'Domain could not be removed' + error,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          })
      }
    },
    addDomain() {
      AdministrationService.addCorsDomain(this.newDomain)
        .then(response => {
          this.newDomain = null
          this.fetchCors()
          Toastify({
            text: 'Domain successfully added',
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true,
            className: 'toastify-content'
          }).showToast()
        })
        .catch(err => {
          let error = ''
          if (err.response.data.description){
            error = err.response.data.description
          }
          Toastify({
            text: 'Domain could not be added' + error,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true,
            className: 'toastify-content'
          }).showToast()
        })
    },
    deleteConfirmation(id) {
      this.idToRemove = id
      cash('#delete-confirmation-modal').modal('show')
    },
    domainName(domain) {
      if (domain.startsWith('*.')) {
        return domain.slice(2)
      } else {
        return domain
      }
    },
    exportCSV(){
      AdministrationService.exportCors()
      .then(response => {
        var filename = "";
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = filename;
        anchor.click();
      })
      .catch(err => {
        let error = ''
        if (err.response.data.description){
          error = err.response.data.description
        }
        Toastify({
            text: "Cors couldn't be exported" + error,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: 'top',
            position: 'right',
            stopOnFocus: true,
            className: 'toastify-content'
          }).showToast()
        }
      )
    },
  }
}
</script>
