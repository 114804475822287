<template>
  <Chart
    type="doughnut"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    values: {
      type: Array,
      default() {
        return []
      }
    },
    labelsArray: {
      type: Array,
      default() {
        return []
      }
    }
  },
  setup(props) {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        labels: props.labelsArray,
        datasets: [
          {
            data: props.values,
            backgroundColor: ['#fbab60', '#FFC533', '#ADD8E6', '#F0E68C', '#EEE8AA', '#FFE4B5', '#FFDAB9'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3', '#F0E68C', '#EEE8AA', '#FFE4B5', '#FFDAB9'],
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      return {
        cutoutPercentage: 45,
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: 'label',
            fontColor: 'white',
            overlap: false,
            fontStyle: 'bold'
          }
        }
      }
    })

    return {
      data,
      options
    }
  }
})
</script>
