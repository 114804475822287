<template>
  <div
    id="add-organization-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <a
          data-dismiss="modal"
          href="javascript:;"
        >
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10">
          <div class="pl-5">
            <b>{{ $t("administration.Add an organization") }}</b>
          </div>
          <div class="p-5 flex">
            <span class="self-center w-1/4">{{ $t("administration.Name") }}: </span>
            <input
              v-model="newOrganization"
              type="text"
              class="form-input intro-x login__input form-control border-gray-300 ml-5"
            >
          </div>
          <div class="px-5 pt-4 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn add-button"
              @click="addOrganization"
            >
              {{ $t("administration.Add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toastify from 'toastify-js'
import AdministrationService from '@/services/AdministrationService'
import moment from 'moment-timezone'

export default {
  name: 'AddOrganizationModal',
  emits: ['closed'],
  data() {
    return {
      newOrganization: '',
    }
  },
  created() {
    this.moment = moment
  },
  methods: {
    addOrganization() {
      AdministrationService.createOrganization(this.newOrganization)
          .then(response => {
            this.showToast('Organization successfully added')
            this.newOrganization = ''
            this.closeModal()
            this.$emit('closed')
          })
          .catch(err => {
            let error = ''
            if (err.response.data.description){
              error = err.response.data.description
            }
            this.showToast('Organization could not be added ' + error)
            this.closeModal()
          })
    },
    showModal() {
      cash('#add-organization-modal').modal('show')
    },
    closeModal() {
      cash('#add-organization-modal').modal('hide')
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  }
}
</script>
