export const User = {
  namespaced: true,
  state: {
    name: '',
    accessToken: '',
    roles: []
  },
  mutations: {
    LOGIN(state, payload) {
      state.name = payload.name
      state.accessToken = payload.accessToken
      if(payload.roles) {
        state.roles = payload.roles
      } else {
        state.roles = []
      }
      
    },
    LOGOUT(state) {
      state.name = ''
      state.accessToken = ''
      state.roles = []
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('LOGIN', payload)
    },
    logout({ commit }) {
      commit('LOGOUT')
    }
  }
}
