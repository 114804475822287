import { createRouter, createWebHistory } from 'vue-router'

import Overview from '../views/overview/Overview.vue'
import Devices from '../views/overview/Devices.vue'
import Summary from '../views/overview/Summary.vue'
import Administration from '../views/administration/Administration.vue'
import Navbar from '../layouts/navbar/Main.vue'

import Organizations from '../views/administration/components/Organizations'
import Billing from '../views/administration/components/Organizations/Billing.vue'
import InvoiceDetails from '../views/administration/components/Organizations/InvoiceDetails.vue'
import Tags from '../views/administration/components/Tags'
import AtrList from '../views/administration/components/AtrList'
import ManageCors from '../views/administration/components/ManageCors'
import Configuration from '../views/administration/components/Configuration/Configuration'

import Login from '../views/login/Main.vue'
// import Register from '../views/register/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'

const routes = [
  {
    path: '/',
    component: Navbar,
    children: [
      {
        path: '/',
        name: 'overview',
        component: Overview,
        children: [
          {
            path: '/',
            name: 'summary',
            component: Summary
          },
          {
            path: '/devices',
            name: 'devices',
            component: Devices
          }
        ]
      },
      {
        path: '/administration',
        name: 'administration',
        component: Administration,
        children: [
          {
            path: 'organizations',
            name: 'organizations',
            component: Organizations
          },
          {
            path: 'billing/:orgId',
            name: 'billing',
            component: Billing
          },
          {
            path: 'invoice-details/:invoiceId',
            name: 'invoiceDetails',
            component: InvoiceDetails
          },
          {
            path: 'tags',
            name: 'tags',
            component: Tags
          },
          {
            path: 'atrList',
            name: 'atrList',
            component: AtrList
          },
          {
            path: 'manageCors',
            name: 'manageCors',
            component: ManageCors
          },
          {
            path: 'configuration',
            name: 'configuration',
            component: Configuration
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: Register
  // },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
