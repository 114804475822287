<template>
  <div class="p-5">
    <div class="col-span-12 flex p-4">
      <div class="font-medium text-xl">
        <b>{{ $t("configuration.General configuration") }}</b>
      </div>
    </div>
    <div class="col-span-12 flex p-4 grid grid-cols-12 pl-16">
      <div class="font-medium text-xl col-span-2">
        {{ $t("configuration.DS cycle") }}:
      </div>
      <input
        v-model="dsCycle"
        type="number"
        class="form-input intro-x login__input form-control border-gray-300 col-span-2"
        min="30"
      >
      <div class="font-medium ml-5 pt-2 intro-x">
        {{ $t("configuration.seconds") }}
      </div>
    </div>
    <button
      class="btn t1c-color-background text-white m-4 ml-16"
      @click="dsCycleUpdate()"
    >
      {{ $t("configuration.Update") }}
    </button>
  </div>
</template>

<script>
import ConfigurationService from '@/services/ConfigurationService'
import Toastify from 'toastify-js'

export default {
  name: 'GeneralConfiguration',
  data() {
    return {
      dsCycle: 0
    }
  },
  created() {
    this.getGeneralConfig()
  },
  methods: {
    getGeneralConfig() {
      ConfigurationService.getGeneralConfig()
        .then(
          (res) => {
            this.dsCycle = res.data.data.config.syncIntervalSeconds
          },
          (err) => {
            let error = 'config could not be fetched'
            if (err.response.data.description){
              error = err.response.data.description
            }
            Toastify({
              text: error,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          }
        )
    },
    dsCycleUpdate() {
      ConfigurationService.setGeneralConfig(this.dsCycle)
        .then(
          (res) => {
            Toastify({
              text: 'General config has been updated',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          },
          (err) => {
            let error = 'Config could not be updated'
            if (err.response.data.description){
              error = err.response.data.description
            }
            Toastify({
              text: error,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          }
        )
    }
  }
}
</script>
