<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex justify-between p-4">
        <div class="font-medium text-3xl">
          <b>{{ $t("administration.Organizations") }}</b>
        </div>
        <div
          class="export"
          @click="exportCSV()"
        >
          <i
            class="fas fa-file-csv fa-2x"
            color="grey"
          />
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 lg:col-span-4 grid grid-cols-12 px-5 pt-5 adjusted-height">
        <div class="col-span-4 text-lg pt-2">
          {{ $t("overview.Organization") }}:
        </div>
        <div class="col-span-6 pl-3">
          <TomSelect
            v-model="organizationFilter"
            class="w-full"
            multiple
          >
            <option
              v-for="(organization, index) in fetchedOrganizations"
              :key="index"
              :value="organization.id"
            >
              {{ organization.name }}
            </option>
          </TomSelect>
          <div
            v-if="isSysAdmin"
            class="text-right pr-2"
          >
            <span
              class="cursor-pointer"
              @click="showModal('addOrganisationModal')"
            >
              <i
                class="fas fa-plus-circle pr-3 pt-3 fa-2x"
                color="#e05512"
              />
            </span>
            {{ $t("administration.Add an organization") }}
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 mt-10 md:mt-0 lg:col-span-4 grid grid-cols-12 p-5 adjusted-height">
        <div class="col-span-4 text-lg pt-2">
          {{ $t("overview.Labels") }}:
        </div>
        <div class="col-span-6 pl-3">
          <TomSelect
            v-model="labelFilter"
            class="w-full"
            multiple
          >
            <option
              v-for="(label, index) in organizationsLabels"
              :key="index"
              :value="label.id"
            >
              {{ label.name }}
            </option>
          </TomSelect>
          <div class="text-right pr-2">
            <span
              class="cursor-pointer"
              @click="showModal('addLabelModal')"
            >
              <i
                class="fas fa-plus-circle pr-3 pt-3 fa-2x"
                color="#e05512"
              />
            </span>
            {{ $t("administration.Add a label") }}
          </div>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-4 grid grid-cols-12 p-5 adjusted-height mt-14 lg:mt-0">
        <div class="col-span-12 grid grid-cols-12">
          <div class="col-span-2 text-lg pt-2">
            {{ $t("administration.Period") }}:
          </div>
          <div class="col-span-5 pl-3">
            <input
              v-model="periodStartFilter"
              type="date"
              class="form-input intro-x login__input form-control border-gray-300"
              :max="periodEndFilter"
            >
          </div>
          <div class="col-span-5 pl-3">
            <input
              v-model="periodEndFilter"
              type="date"
              class="form-input intro-x login__input form-control border-gray-300"
              :min="periodStartFilter"
            >
          </div>
        </div>
        <div class="col-span-12 text-right mt-5">
          <button
            class="btn btn-large apply-button"
            @click="filterData"
          >
            {{ $t("overview.Apply") }}
          </button>
          <button
            class="btn btn-block clear-button text-white"
            @click="clearFilterData"
          >
            {{ $t("overview.Clear") }}
          </button>
        </div>
      </div>

      <div class="col-span-12 p-5 mt-20">
        <div class="overflow-x-auto">
          <table class="table">
            <colgroup>
              <col
                span="1"
                style="width: 20%;"
              >
              <col
                span="1"
                style="width: 15%;"
              >
              <col
                span="1"
                style="width: 15%;"
              >
              <col
                span="1"
                style="width: 15%;"
              >
              <col
                span="1"
                style="width: 10%;"
              >
            </colgroup>
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  {{ $t("overview.Organization") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("overview.Total transactions") }}
                </th>
                <th
                  v-if="isSysAdmin"
                  class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center"
                >
                  {{ $t("administration.Billings") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  {{ $t("overview.Created") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  {{ $t("administration.Updated on") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap" />
                <!--                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap" />-->
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(organization, index) in dataToDisplay"
                :key="index"
              >
                <tr class="organization-row">
                  <td class="border-b whitespace-nowrap organization-name">
                    <b>{{ organization.name }}</b>
                  </td>
                  <td class="border-b whitespace-nowrap" />
                  <td
                    v-if="isSysAdmin"
                    class="border-b whitespace-nowrap text-center"
                  >
                    <router-link :to="{ name: 'billing', params: {orgId: organization.id} }">
                      <i
                        class="fas fa-file-invoice-dollar fa-2x"
                        color="green"
                      />
                    </router-link>  
                  </td>
                  <td class="border-b whitespace-nowrap" />
                  <td class="border-b whitespace-nowrap" />
                  <td class="border-b whitespace-nowrap" />
                </tr>
                <template
                  v-for="(label, index) in labelsByOrganizations(organization.id)"
                  :key="index"
                >
                  <tr>
                    <td class="border-b whitespace-nowrap">
                      &emsp; {{ label.name }}
                      <span
                        v-if="!opened.some(element => element.organizationId === organization.id && element.labelId === label.id) && tagsByLabels(label.id, organization.id).length > 0"
                        class="cursor-pointer"
                        @click="toggle({organizationId: organization.id, labelId: label.id})"
                      >
                        <ChevronDownIcon />
                      </span>
                      <span
                        v-if="opened.some(element => element.organizationId === organization.id && element.labelId === label.id)"
                        class="cursor-pointer"
                        @click="toggle({organizationId: organization.id, labelId: label.id})"
                      >
                        <ChevronUpIcon />
                      </span>
                    </td>
                    <td class="border-b whitespace-nowrap text-center">
                      {{ labelTransactions(label.id) }}
                    </td>
                    <td
                      v-if="isSysAdmin"
                      class="border-b whitespace-nowrap"
                    />
                    <td class="border-b whitespace-nowrap">
                      {{ moment(labelById(label.id).createdOn).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss") }}
                    </td>
                    <td class="border-b whitespace-nowrap">
                      {{ moment(labelById(label.id).updatedOn).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss") }}
                    </td>
                    <td class="border-b whitespace-nowrap">
                      <span
                        class="copy-key cursor-pointer"
                        @click="copyTextToClipboard(label.apiKey)"
                      >
                        <i class="fas fa-key" />
                      </span>&ensp;
                      <span
                        class="cursor-pointer"
                        @click="showModal('generateKeyModal'), labelApiKey = label.id"
                      >
                        <i
                          class="fas fa-key"
                          color="#e05512"
                        />+
                      </span>&ensp;
                      <span class="cursor-pointer">
                        <div class="dropdown inline-block">
                          <span
                            id="dropdownMenuButton"
                            class="dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ><i class="fas fa-cog" /></span>
                          <div
                            class="dropdown-menu border border-gray-200 rounded w-56 bg-white"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <ul
                              class="py-1"
                              aria-labelledby="dropdownLeftButton"
                            >
                              <li class="border-b border-gray-200 py-2">
                                <button
                                  class="block py-2 px-4"
                                  @click="showModal('deleteLabelModal'), labelToDelete = label.id"
                                >{{ $t("administration.Delete label") }}</button>
                              </li>
                              <li class=" py-2">
                                <button
                                  class="block py-2 px-4 text-sm text-gray-700 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                  @click="newLabel.id = label.id, newLabel.orgId = label.orgId, showModal('renameLabelModal')"
                                >
                                  Rename label
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </span>
                    </td>
                  </tr>
                  <template v-if="opened.some(element => element.organizationId === organization.id && element.labelId === label.id)">
                    <tr
                      v-for="(tag, index) in tagsByLabels(label.id, organization.id)"
                      :key="index"
                      class="sub-row"
                    >
                      <td class="border-b whitespace-nowrap">
                        &emsp;&emsp; {{ tag.tag === '_unknown_' ? 'Untagged' : tag.tag }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ tagTransactions(tag) }}
                      </td>
                      <td class="border-b whitespace-nowrap" />
                      <td class="border-b whitespace-nowrap" />
                      <td class="border-b whitespace-nowrap" />
                      <td class="border-b whitespace-nowrap" />
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 my-10 pl-12 ml-5">
        <div class="col-span-2 flex">
          <select
            v-model="numberPerPage"
            class="number-per-page"
            aria-label="Default select example"
            @change="currentPage > totalPages ? currentPage = totalPages : ''"
          >
            <option value="5">
              5
            </option>
            <option value="10">
              10
            </option>
            <option value="15">
              15
            </option>
            <option value="20">
              20
            </option>
            <option value="50">
              50
            </option>
          </select>
          <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
        </div>
        <div class="col-span-4 text-right">
          <span
            class="pagination-most-left"
            @click="currentPage = 1"
          ><i class="fas fa-angle-double-left" /></span>
          <span
            class="pagination-left"
            @click="currentPage > 1 ? currentPage -= 1 : ''"
          ><i class="fas fa-angle-left" /></span>
          <span class="current-page">{{ currentPage }} of {{ totalPages }}</span>
          <span
            class="pagination-right"
            @click="currentPage < totalPages ? currentPage += 1 : ''"
          ><i class="fas fa-angle-right" /></span>
          <span
            class="pagination-most-right"
            @click="currentPage = totalPages"
          ><i class="fas fa-angle-double-right" /></span>
        </div>
      </div>
    </div>

    <!--    BEGIN ADD ORGANISATION MODAL-->
    <AddOrganizationModal
      ref="addOrganisationModal"
      @closed="fetchOrganizations"
    />
    <!--    END ADD ORGANISATION MODAL-->

    <!--    BEGIN ADD LABEL MODAL-->
    <AddLabelModal
      ref="addLabelModal"
      :fetched-organizations="fetchedOrganizations"
      @closed="labelAdded"
    />
    <!--    END ADD LABEL MODAL-->

    <!--    BEGIN RENAME LABEL MODAL-->
    <RenameLabelModal
      ref="renameLabelModal"
      :organizations="fetchedOrganizations"
      :label="newLabel"
      @renamed="labelAdded"
    />
    <!--    END RENAME LABEL MODAL-->

    <!--    BEGIN GENERATE KEY MODAL-->
    <GenerateKeyModal
      ref="generateKeyModal"
      :label-api-key="labelApiKey"
      @keyRegenerated="keyRegenerated"
    />
    <!--    END GENERATE KEY MODAL-->

    <!--    BEGIN DELETE LABEL MODAL-->
    <DeleteLabelModal
      ref="deleteLabelModal"
      :label-to-delete="labelToDelete"
      @deleted="labelDeleted"
    />
    <!--  END DELETE LABEL MODAL-->
  </div>
</template>

<script>
import Toastify from 'toastify-js'
import AdministrationService from '@/services/AdministrationService'
import moment from 'moment-timezone'
import AddOrganizationModal from "@/views/administration/components/Organizations/AddOrganizationModal";
import AddLabelModal from "@/views/administration/components/Organizations/AddLabelModal";
import RenameLabelModal from "@/views/administration/components/Organizations/RenameLabelModal";
import DeleteLabelModal from "@/views/administration/components/Organizations/DeleteLabelModal";
import GenerateKeyModal from "@/views/administration/components/Organizations/GenerateKeyModal";

export default {
  name: 'Organizations',
  components: {GenerateKeyModal, DeleteLabelModal, RenameLabelModal, AddLabelModal, AddOrganizationModal},
  data(){
    return {
      opened: [],
      organizationFilter: [],
      labelFilter: [],
      periodStartFilter: null,
      periodEndFilter: null,
      currentPage: 1,
      numberPerPage: 10,
      fetchedOrganizations: [],
      fetchedLabels: [],
      filteredOrganizations: null,
      filteredLabels: null,
      newOrganization: '',
      newLabel: {},
      organizations: [],
      labels: [],
      labelTagReport: [],
      periodFilteredData: null,
      filteredByPeriod: false,
      labelApiKey: null,
      organizationLabelBilling: null,
      billingTypeActive: '',
      labelToDelete: null,
      invoices: [],
      transactionSummaries: []
    }
  },
  computed: {
    dataToDisplay() {
      if(this.filteredOrganizations){
        return this.filteredOrganizations.slice((this.currentPage*this.numberPerPage-this.numberPerPage),this.currentPage*this.numberPerPage)
      } else {
        return this.fetchedOrganizations.slice((this.currentPage*this.numberPerPage-this.numberPerPage),this.currentPage*this.numberPerPage)
      }
    },
    totalPages() {
      if(this.filteredOrganizations){
        return Math.ceil(this.filteredOrganizations.length/this.numberPerPage)
      } else {
        return Math.ceil(this.fetchedOrganizations.length/this.numberPerPage)
      }
    },
    organizationsLabels(){
      if(this.organizationFilter.length > 0){
        return this.fetchedLabels.filter(label => this.organizationFilter.includes(label.orgId))
      } else {
        return this.fetchedLabels
      }
    },
    isSysAdmin() {
      return this.$store.state.user.roles.includes('SysAdmin')
    }
  },
  created() {
    this.moment = moment
    this.fetchOrganizations()
    this.fetchLabels()
    this.labelTagTransactionReport()
  },
  methods: {
    labelDeleted() {
      if(this.filteredLabels) {
        let index = this.filteredLabels.findIndex(label => label.id === this.labelToDelete)
        this.filteredLabels.splice(index, 1)
      }
      this.fetchLabels()
    },
    labelAdded() {
      AdministrationService.getLabels()
      .then(response => {
        this.fetchedLabels = response.data.data.results
        this.filterData()
      })
    },
    invoicesByLabel(labelId){
      return this.invoices.filter(invoice => invoice.label === labelId)
    },
    labelTagTransactionReport() {
      AdministrationService.getLabelTagTransactionReport()
      .then(response => {
        this.labelTagReport = response.data.data.summary.report
      })
    },
    fetchOrganizations(){
      AdministrationService.getOrganizations()
      .then(response => {
        this.fetchedOrganizations = response.data.data.results
        this.fetchedOrganizations.sort(function(a, b) {
          var nameA = a.name.toUpperCase()
          var nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      })
    },
    fetchLabels(){
      AdministrationService.getLabels()
      .then(response => {
        this.fetchedLabels = response.data.data.results
      })
    },
    labelsByOrganizations(organizationId){
      if(this.filteredLabels){
        return this.filteredLabels.filter(label => label.orgId === organizationId)
      } else {
        return this.fetchedLabels.filter(label => label.orgId === organizationId)
      }
    },
    labelById(labelId){
      return this.fetchedLabels.filter(label => label.id === labelId)[0]
    },
    tagTransactions(object){
      if(!this.filteredByPeriod){
        return object.total
      } else {
        return this.periodFilteredData.filter(element => element.tag === object.tag && element.labelId === object.labelId && element.orgId === object.orgId).reduce((a, b) => a + (b['total'] || 0), 0)
      }
    },
    tagsByLabels(labelId, orgId){
      return this.labelTagReport.filter(element => element.orgId === orgId && element.labelId === labelId)
    },
    toggle(object) {
    	const index = this.opened.indexOf(object);
      if (this.opened.some(open => open.organizationId === object.organizationId && open.labelId === object.labelId)) {
        this.opened.splice(index, 1)
      } else {
      	this.opened.push(object)
      }
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(
        response => {
          this.showToast('Key successfully copied')
        },
        err => {
          let error = ''
          if (err.response.data.description){
            error = err.response.data.description
          }
          this.showToast('Error: the key could not be copied' + error)
        }
      );
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        this.showToast('Fallback: Copying key command was ' + msg)
      } catch (err) {
        let error = ''
        if (err.response.data.description){
          error = err.response.data.description
        }
        this.showToast('Error: the key could not be copied' + error)
      }

      document.body.removeChild(textArea);
    },
    labelTransactions(labelId){
      if(this.filteredByPeriod){
        return this.periodFilteredData.filter(element => element.labelId === labelId).reduce((a, b) => a + (b['total'] || 0), 0)
      } else {
        return this.labelTagReport.filter(element => element.labelId === labelId).reduce((a, b) => a + (b['total'] || 0), 0)
      }
    },
    periodTagTransactions(tag, labelId){
      return this.periodFilteredData.filter(element => element.tag === tag && element.labelId === labelId).reduce((a, b) => a + (b['total'] || 0), 0)
    },
    filterData(){
      let filteredOrganizations = this.fetchedOrganizations;
      if (this.organizationFilter.length !== 0){
        filteredOrganizations = filteredOrganizations.filter(organization => this.organizationFilter.includes(organization.id))
      }
      this.filteredOrganizations = filteredOrganizations

      let filteredLabels = this.fetchedLabels
      if (this.labelFilter.length !== 0){
        filteredLabels = filteredLabels.filter(label => this.labelFilter.includes(label.id))
        let orgArray = filteredLabels.map(a => a.orgId);
        this.filteredOrganizations = filteredOrganizations.filter(organization => orgArray.includes(organization.id))
      }
      this.filteredLabels = filteredLabels

      if(this.periodStartFilter || this.periodEndFilter) {
        AdministrationService.periodTransactionReport(this.periodStartFilter, this.periodEndFilter)
        .then(response => {
          this.periodFilteredData = response.data.data.summary.report
          this.filteredByPeriod = true
        })
      }
      if(this.currentPage > this.totalPages){
        this.currentPage = this.totalPages
      }
    },
    clearFilterData(){
      this.filteredOrganizations = null
      this.filteredLabels = null
      this.organizationFilter = []
      this.labelFilter = []
      this.periodStartFilter = null
      this.periodEndFilter = null
      this.filteredByPeriod = false
    },
    addOrganization(){
      AdministrationService.createOrganization(this.newOrganization)
      .then(response => {
        this.showToast('Organization successfully added')
        this.newOrganization = ''
      })
      .catch(err => {
        let error = ''
        if (err.response.data.description){
          error = err.response.data.description
        }
        this.showToast('Organization could not be added ' + error)
      })
    },
    keyRegenerated(payload){
      let index = this.fetchedLabels.findIndex(element => element.id === this.labelApiKey)
      this.fetchedLabels[index].apiKey = payload
    },
    exportCSV(){
      AdministrationService.getTransactionsCSV('LABEL_TAG', this.periodStartFilter, this.periodEndFilter)
      .then(response => {
        var filename = "";
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = filename;
        anchor.click();
      })
    },
    showModal(modalName) {
      this.$refs[modalName].showModal()
      // cash('#' + modalName).modal('show')
    },
    cancelModal(modalName) {
      this.$refs[modalName].hideModal()
      // cash('#' + modalName).modal('hide')
    },
    showDialog(modalName) {
      // this.$refs[modalName].showModal()
      cash('#' + modalName).modal('show')
    },
    cancelDialog(modalName) {
      // this.$refs[modalName].hideModal()
      cash('#' + modalName).modal('hide')
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  }
}
</script>
