<template>
  <div class="intro-y">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Overview',
  data() {
    return {
      activeTab: 'Summary',
      count: null
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>
