<template>
  <div
    id="add-label-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <a
          data-dismiss="modal"
          href="javascript:;"
        >
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10">
          <div class="pl-5">
            <b>{{ $t("administration.Add a label") }}</b>
          </div>
          <div class="p-5 flex">
            <span class="self-center w-2/5">{{ $t("administration.Name") }}: </span>
            <input
              v-model="newLabel.name"
              type="text"
              class="form-input intro-x login__input form-control border-gray-300 ml-8"
            >
          </div>
          <div class="p-5 flex">
            <span class="self-center w-2/5">{{ $t("overview.Organization") }}: </span>
            <select
              v-model="newLabel.orgId"
              class="form-select ml-5"
            >
              <option
                v-for="(organization, index) in fetchedOrganizations"
                :key="index"
                :value="organization.id"
              >
                {{ organization.name }}
              </option>
            </select>
          </div>
          <div class="px-5 pt-4 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn add-button"
              @click="addLabel"
            >
              {{ $t("administration.Add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toastify from 'toastify-js'
import AdministrationService from '@/services/AdministrationService'
import moment from 'moment-timezone'

export default {
  name: 'AddLabelModal',
  props: {
    fetchedOrganizations: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['closed'],
  data() {
    return {
      newLabel: {},
    }
  },
  created() {
    this.moment = moment
  },
  methods: {
    addLabel(){
      AdministrationService.createUpdateLabel(this.newLabel)
          .then(response => {
            this.showToast('Label successfully added')
            this.newLabel = {}
            this.$emit('closed')
            this.closeModal()
          })
          .catch(err => {
            let error = err
            console.log(err)
            this.showToast('Label could not be added, please contact the Sysadmin of your organization')
            this.closeModal()
          })
    },
    showModal() {
      cash('#add-label-modal').modal('show')
    },
    closeModal() {
      cash('#add-label-modal').modal('hide')
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  }
}
</script>
