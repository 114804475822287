const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'overview',
        title: 'Overview',
        subMenu: [
          {
            icon: '',
            pageName: 'summary',
            title: 'Summary'
          },
          {
            icon: '',
            pageName: 'devices',
            title: 'Devices'
          },
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'Administration',
        title: 'Administration',
        subMenu: [
          {
            icon: '',
            pageName: 'organizations',
            title: 'Organizations',
            ignore: true
          },
          {
            icon: '',
            pageName: 'tags',
            title: 'Tags',
            ignore: true
          },
          // {
          //   icon: '',
          //   pageName: 'atrList',
          //   title: 'ATR list',
          //   ignore: true
          // },
          {
            icon: '',
            pageName: 'manageCors',
            title: 'Cors management',
            ignore: true
          },
          {
            icon: '',
            pageName: 'configuration',
            title: 'Configuration',
            ignore: true
          },
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
