<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex p-4">
        <div class="font-medium text-3xl">
          <b>{{ $t("administration.Configuration") }}</b>
        </div>
      </div>

      <div class="col-span-12">
        <SSLConfiguration />
      </div>
      <div class="col-span-12">
        <GeneralConfiguration />
      </div>
      <div class="col-span-12">
        <VersionManagement />
      </div>
    </div>
  </div>
</template>

<script>
import SSLConfiguration from '@/views/administration/components/Configuration/SSLConfiguration'
import GeneralConfiguration from '@/views/administration/components/Configuration/GeneralConfiguration'
import VersionManagement from '@/views/administration/components/Configuration/VersionManagement'
import store from '@/store/index.js';

export default {
  name: 'Configuration',
  components: { VersionManagement, GeneralConfiguration, SSLConfiguration },
  beforeRouteEnter(routeTo, routeFrom, next) {
    if(store.state.user.roles.includes('SysAdmin')) {
      next(true)
    } else {
      next({ name: 'summary' })
    }
  },
}
</script>
