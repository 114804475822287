<template>
  <div class="administration">
    <div class="grid grid-cols-12">
      <div class="col-span-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Administration',
}
</script>
