import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import * as Keycloak from "keycloak-js";
import "./libs";
import { library } from "@fortawesome/fontawesome-svg-core";
import { dom } from "@fortawesome/fontawesome-svg-core";

// SASS Theme
import "./assets/sass/app.scss";

import i18n from "./i18n";

const initOptions = {
  url: window.VUE_APP_ENV_KEYCLOAK_URL,
  realm: window.VUE_APP_ENV_KEYCLOAK_REALM,
  clientId: window.VUE_APP_ENV_KEYCLOAK_CLIENT_ID,
  onLoad: "login-required",
};

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);

const keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      const payload = {
        name: keycloak.tokenParsed.preferred_username,
        accessToken: keycloak.token,
        roles: keycloak.tokenParsed.resource_access[window.VUE_APP_ENV_KEYCLOAK_CLIENT_ID].roles,
      };

      const app = createApp(App)
        .use(store)
        .use(router)
        .use(i18n);
      globalComponents(app);
      utils(app);
      app.provide("keycloak", keycloak);
      app.mount("#app");
      store.dispatch("user/login", payload);
    }

    // Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            const payloadRefreshedToken = {
              name: keycloak.tokenParsed.preferred_username,
              accessToken: keycloak.token,
              roles:
                keycloak.tokenParsed.resource_access[window.VUE_APP_ENV_KEYCLOAK_CLIENT_ID].roles,
            };
            store.dispatch("user/login", payloadRefreshedToken);
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch((err) => {
    console.error("Authentication Failed ", err);
  });
