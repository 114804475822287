<template>
  <div>
    <div
      class="border-b border-theme-29 -mt-10 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 pt-3 md:pt-0 mb-10"
    >
      <div class="top-bar-boxed flex items-center">
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'summary' }"
          tag="a"
          class="-intro-x hidden md:flex"
        >
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="w-6"
            src="@/assets/images/t1c-logo.png"
          >
          <span class="text-white text-lg ml-3">
            Dashboard
          </span>
        </router-link>
        <!-- END: Logo -->
        <!-- BEGIN: Breadcrumb -->
        <div class="-intro-x breadcrumb breadcrumb--light mr-auto" />
        <!-- END: Breadcrumb -->
        <!-- BEGIN: Account Menu -->
        <div class="intro-x dropdown h-8">
          <div
            class="dropdown-toggle mt-2 text-white"
            role="button"
            aria-expanded="false"
          >
            <UserIcon />
          </div>
          <div class="dropdown-menu w-56">
            <div
              class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
            >
              <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                <div class="font-medium">
                  {{ username }}
                </div>
                <!--                <div class="text-xs text-theme-41 mt-0.5 dark:text-gray-600">-->
                <!--                  {{ $f()[0].jobs[0] }}-->
                <!--                </div>-->
              </div>
              <div class="p-2 border-t border-theme-27 dark:border-dark-3">
                <a
                  class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md cursor-pointer"
                  @click="logout"
                >
                  <ToggleRightIcon class="w-4 h-4 mr-2" />
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Account Menu -->
      </div>
    </div>
    <!-- END: Top Bar -->
    <!-- BEGIN: Top Menu -->
    <nav class="top-nav">
      <ul>
        <!-- BEGIN: First Child -->
        <li
          v-for="(menu, menuKey) in formattedMenu"
          :key="menuKey"
        >
          <a
            :href="
              menu.subMenu
                ? 'javascript:;'
                : router.resolve({ name: menu.pageName }).path
            "
            class="top-menu"
            :class="{
              'top-menu--active': menu.active
            }"
            @click="linkTo(menu, router, $event)"
          >
            <div class="top-menu__icon">
              <component :is="menu.icon" />
            </div>
            <div class="top-menu__title">
              {{ menu.title }}
              <ChevronDownIcon
                v-if="menu.subMenu"
                class="top-menu__sub-icon"
              />
            </div>
          </a>
          <!-- BEGIN: Second Child -->
          <ul v-if="menu.subMenu">
            <div
              v-for="(subMenu, subMenuKey) in menu.subMenu"
              :key="subMenuKey"
            >
              <li v-if="hasAccess(subMenu.title)">
                <a
                  :href="
                    subMenu.subMenu
                      ? 'javascript:;'
                      : router.resolve({ name: subMenu.pageName }).path
                  "
                  class="top-menu"
                  @click="linkTo(subMenu, router, $event)"
                >
                  <div class="top-menu__icon">
                    <ChevronRightIcon />
                  </div>
                  <div class="top-menu__title">
                    {{ subMenu.title }}
                    <ChevronDownIcon
                      v-if="subMenu.subMenu"
                      class="top-menu__sub-icon"
                    />
                  </div>
                </a>
                <!-- BEGIN: Third Child -->
                <ul v-if="subMenu.subMenu">
                  <li
                    v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                    :key="lastSubMenuKey"
                  >
                    <a
                      :href="
                        lastSubMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: lastSubMenu.pageName }).path
                      "
                      class="top-menu"
                      @click="linkTo(lastSubMenu, router, $event)"
                    >
                      <div class="top-menu__icon">
                        <component :is="'zap-icon'" />
                      </div>
                      <div class="top-menu__title">
                        {{ lastSubMenu.title }}
                      </div>
                    </a>
                  </li>
                </ul>
              
                <!-- END: Third Child -->
              </li>
            </div>
          </ul>
          <!-- END: Second Child -->
        </li>
        <!-- END: First Child -->
      </ul>
    </nav>
    <!-- END: Top Menu -->
    <!-- BEGIN: Content -->
    <div class="content">
      <router-view />
    </div>
    <!-- END: Content -->
    <Footer />
  </div>
</template>

<script>
import { searchDropdown, showSearchDropdown, hideSearchDropdown, nestedMenu, linkTo } from './index'
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { helper as $h } from '@/utils/helper'
import Footer from "@/layouts/footer/Main";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { mapState } from 'vuex'

export default defineComponent({
  components: {
    Footer
  },
  inject: ['keycloak'],
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const topMenu = computed(() => nestedMenu(store.state.topMenu.menu, route))

    watch(
        computed(() => route.path),
        () => {
          formattedMenu.value = $h.toRaw(topMenu.value)
        }
    )

    onMounted(() => {
      cash('body')
          .removeClass('error-page')
          .removeClass('login')
          .addClass('main')
      formattedMenu.value = $h.toRaw(topMenu.value)
    })

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      formattedMenu,
      router,
      linkTo
    }
  },
  data() {
    return {
      username: ''
    }
  },
  computed: {
    currentRouteName() {
      if (this.$route.name === 'organizations' || this.$route.name === 'tags' || this.$route.name === 'atrList' || this.$route.name === 'manageCors') {
        return 'administration'
      } else if (this.$route.name === 'overview' || this.$route.name === 'devices') {
        return 'overview'
      } else {
        return this.$route.name
      }
    },
    ...mapState({
      userRole: state => state.user.roles,
    })
  },
  created() {
    this.username = this.$store.state.user.name
  },
  methods: {
    logout() {
      this.keycloak.logout({ redirectUri: window.location.origin })
      this.$store.dispatch('user/logout')
    },
    hasAccess(tab) {
      if(this.userRole.includes('SysAdmin')) {
        return true
      } else if(tab !== 'Cors management' && tab !== 'Configuration') {
        return true
      } else {
        return false
      }
    },
  }
})
</script>
