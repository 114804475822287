<template>
  <div
    id="generate-key-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">
              {{ $t("administration.Are you sure?") }}
            </div>
            <div class="text-gray-600 mt-2">
              {{ $t("administration.Replace key") }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
              @click="closeModal"
            >
              {{ $t("administration.Cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-danger w-24"
              data-dismiss="modal"
              @click="regenerateApiKey()"
            >
              {{ $t("administration.Generate") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toastify from 'toastify-js'
import AdministrationService from '@/services/AdministrationService'
import moment from 'moment-timezone'

export default {
  name: 'GenerateKeyModal',
  props: {
    labelApiKey: {
      type: String,
      default: ''
    }
  },
  emits: ['keyRegenerated'],
  created() {
    this.moment = moment
  },
  methods: {
    regenerateApiKey(){
      AdministrationService.regenerateApiKey(this.labelApiKey)
          .then(response => {
            console.log(response)
            this.showToast('Api key successfully regenerated')
            this.$emit('keyRegenerated', response.data.data)
            this.closeModal()
          })
          .catch(err => {
            let error = ''
            if (err.response.data.description){
              error = err.response.data.description
            }
            this.showToast('Api key could not be regenerated, please contact the Sysadmin of your organization')
            this.closeModal()
          })
    },
    showModal() {
      cash('#generate-key-modal').modal('show')
    },
    closeModal() {
      cash('#generate-key-modal').modal('hide')
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  }
}
</script>
