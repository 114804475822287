<template>
  <div class="col-span-12 flex p-4">
    <div class="font-medium text-xl">
      <b>{{ $t("configuration.versionManagement.title") }}</b>
    </div>
  </div>

  <a
    href="javascript:;"
    data-toggle="modal"
    data-target="#version-modal"
    class="btn add-button text-white ml-4"
  >{{ $t("configuration.versionManagement.addVersion") }}</a>

  <div class="col-span-12 p-5">
    <div class="overflow-x-auto">
      <table class="table">
        <colgroup>
          <col
            span="1"
            style="width: 20%;"
          >
          <col
            span="1"
            style="width: 30%;"
          >
          <col
            span="1"
            style="width: 15%;"
          >
          <col
            span="1"
            style="width: 15%;"
          >
          <col
            span="1"
            style="width: 20%"
          >
        </colgroup>
        <thead>
          <tr>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ $t("configuration.versionManagement.table.versionHead") }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ $t("configuration.versionManagement.table.apiKeyHead") }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ $t("configuration.versionManagement.table.createdOnHead") }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ $t("configuration.versionManagement.table.updatedOnHead") }}
            </th>
            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
              {{ $t("configuration.versionManagement.table.actionHead") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(version, index) in versions"
            :key="index"
          >
            <tr>
              <td class="border-b whitespace-nowrap">
                <b>v{{ version.id }}</b>
              </td>
              <td class="border-b whitespace-nowrap">
                {{ version.installationApiKey }}
              </td>
              <td class="border-b whitespace-nowrap">
                {{ moment(version.createdOn).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss") }}
              </td>
              <td class="border-b whitespace-nowrap">
                {{ moment(version.updatedOn).tz(moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss") }}
              </td>
              <td class="border-b whitespace-nowrap">
                <button @click="getVersion(version.id)">
                  <EditIcon />
                </button>
                <button @click="removeVersion(version.id)">
                  <XIcon />
                </button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-span-12 grid grid-cols-12 my-10 pl-12 ml-5">
    <div class="col-span-2 flex">
      <select
        v-model="numberPerPage"
        class="number-per-page"
        aria-label="Default select example"
        @change="changePageSize"
      >
        <option value="5">
          5
        </option>
        <option value="10">
          10
        </option>
        <option value="15">
          15
        </option>
        <option value="20">
          20
        </option>
        <option value="50">
          50
        </option>
      </select>
      <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
    </div>
    <div class="col-span-4 text-right">
      <span
        class="pagination-most-left"
        @click="setCurrentPage(0)"
      ><i class="fas fa-angle-double-left" /></span>
      <span
        class="pagination-left"
        @click="previousPage"
      ><i class="fas fa-angle-left" /></span>
      <span class="current-page">{{ getCurrentPage }} of {{ totalPages }}</span>
      <span
        class="pagination-right"
        @click="nextPage"
      ><i class="fas fa-angle-right" /></span>
      <span
        class="pagination-most-right"
        @click="setCurrentPage(totalPages -1)"
      ><i class="fas fa-angle-double-right" /></span>
    </div>
  </div>

  <!--  MODAL-->
  <div
    id="version-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a
          data-dismiss="modal"
          href="javascript:;"
        >
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>

        <div class="modal-body p-10">
          <div class="pl-5 text-center">
            <b>{{ $t("configuration.versionManagement.addVersion") }}</b>
          </div>
          <div class="p-5">
            <p>{{ $t("configuration.versionManagement.table.versionHead") }}</p>
            <input
              v-model="versionObject.id"
              type="text"
              class="form-input intro-x login__input form-control border-gray-300"
            >
          </div>

          <div class="p-5">
            <p>{{ $t("configuration.InstallationApiKey") }}</p>
            <input
              v-model="versionObject.installationApiKey"
              type="text"
              class="form-input intro-x login__input form-control border-gray-300"
            >
          </div>

          <div class="flex p-5">
            <p>
              {{ $t("configuration.Recommended") }}
            </p>
            <input
              v-model="versionObject.recommended"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
            >
          </div>
          <div class="flex p-5">
            <p>
              {{ $t("configuration.Mandatory") }}
            </p>
            <input
              v-model="versionObject.mandatory"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
            >
          </div>
          <div class="flex p-5">
            <p>
              {{ $t("configuration.Allowed") }}
            </p>
            <input
              v-model="versionObject.allowed"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
            >
          </div>
          <div class="flex p-5">
            <p>
              {{ $t("configuration.Visible") }}
            </p>
            <input
              v-model="versionObject.visible"
              class="show-code form-check-switch mr-0 ml-3"
              type="checkbox"
            >
          </div>

          <h1><b>{{ $t("configuration.Binaries") }}</b></h1>
          <div class="p-2 pl-3">
            <p>
              OS
            </p>
            <select
              v-model="binary.os"
              class="form-select box intro-x login__input form-control border-gray-300"
            >
              <option value="MACOS">
                {{ $t("configuration.versionManagement.macosIntel") }}
              </option>
              <option value="MACOSARM">
                {{ $t("configuration.versionManagement.macosArm") }}
              </option>
              <option value="WIN64">
                {{ $t("configuration.versionManagement.windows64") }}
              </option>
              <option value="WIN32">
                {{ $t("configuration.versionManagement.windows32") }}
              </option>
              <option value="UNIX">
                {{ $t("configuration.versionManagement.unix") }}
              </option>
            </select>
          </div>
          <div class="p-2 pl-3">
            <p>
              URI
            </p>
            <input
              v-model="binary.uri"
              type="text"
              class="form-input intro-x login__input form-control border-gray-300"
            >
          </div>

          <button
            type="button"
            class="btn add-button ml-3"
            @click="addBinary"
          >
            {{ $t("configuration.AddBinary") }}
          </button>

          <div class="p-5">
            <div
              v-for="(item, index) in versionObject.uris"
              :key="item"
              class="divide-y divide-grey-100"
            >
              <div class="mb-5 flex">
                <div class="flex-1">
                  <p><b>{{ getTranslatedOS(item.os) }}</b></p>
                  <p>{{ item.uri }}</p>
                </div>
                <div>
                  <button @click="removeBinary(index)">
                    <XIcon class="w-8 h-8 text-gray-500" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 pt-4">
            <button
              type="button"
              data-dismiss="modal"
              class="btn add-button"
              @click="updateVersion()"
            >
              {{ $t("configuration.versionManagement.addVersion") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  MODAL END-->
</template>

<script>
import ConfigurationService from '@/services/ConfigurationService'
import Toastify from 'toastify-js'
import moment from 'moment-timezone'

import i18n from '@/i18n';

const { t } = i18n.global;


export default {
  name: 'VersionManagement',
  data() {
    return {
      from: 0,
      numberPerPage: 10,
      totalPages: 0,
      versions: [],
      binary: {
        os: '',
        uri: ''
      },
      versionObject: {
        id: '',
        recommended: false,
        mandatory: false,
        allowed: true,
        visible: true,
        uris: [],
        installationApiKey: ''
      }
    }
  },
  computed: {
    getCurrentPage() {
      return Math.ceil(this.from / this.numberPerPage) + 1
    },
    getFrom() {
      return this.from
    },
    getPageSize() {
      return this.numberPerPage
    }
  },
  created() {
    this.moment = moment
    this.getVersions(this.from, this.numberPerPage)
  },
  methods: {
    getTranslatedOS(os) {
      switch (os) {
        case 'MACOS':
          return t("configuration.versionManagement.macosIntel")
        case 'MACOSARM':
          return t("configuration.versionManagement.macosArm")
        case 'WIN64':
          return t("configuration.versionManagement.windows64")
        case 'WIN32':
          return t("configuration.versionManagement.windows32")
        case 'UNIX':
          return t("configuration.versionManagement.unix")
        default:
          return os
      }
    },
    addBinary() {
      this.versionObject.uris.push(this.binary)
      this.binary = {
        os: '',
        uri: ''
      }
    },
    removeBinary(index) {
      this.versionObject.uris.splice(index, 1)
    },
    removeVersion(version) {
      ConfigurationService.removeVersion(version).then(res => {
        this.getVersions(this.from, this.numberPerPage)
        Toastify({
          text: `Version ${version} has been sucessfully removed`,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          className: 'toastify-content'
        }).showToast()
      }, err => {
        let error = err
        if (err.response.data.description){
          error = err.response.data.description
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          className: 'toastify-content'
        }).showToast()
      })
    },
    getVersion(version) {
      ConfigurationService.getVersion(version).then(res => {
        this.versionObject = res.data.data
        cash('#version-modal').modal('show')
      }, err => {
        let error = err
        if (err.response.data.description){
          error = err.response.data.description
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          className: 'toastify-content'
        }).showToast()
      })
    },
    getVersions(currentPage, numberPerPage) {
      ConfigurationService.getVersions(currentPage, numberPerPage).then(res => {
        this.versions = res.data.data.results
        this.totalPages = Math.ceil(res.data.data.total / res.data.data.size)
      }, err => {
        let error = err
        if (err.response.data.description){
          error = err.response.data.description
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          className: 'toastify-content'
        }).showToast()
      })
    },
    nextPage() {
      if (this.getCurrentPage < this.totalPages) {
        this.from += this.numberPerPage
        this.getVersions(this.from, this.numberPerPage)
      }
    },
    previousPage() {
      if (this.getCurrentPage > 1) {
        this.from -= this.numberPerPage
        this.getVersions(this.from, this.numberPerPage)
      }
    },
    setCurrentPage(page) {
      this.from = Math.ceil(page * this.numberPerPage)
      this.getVersions(this.from, this.numberPerPage)
    },
    changePageSize() {
      this.from = 0
      this.getVersions(this.from, this.numberPerPage)
    },
    updateVersion() {
      ConfigurationService.updateVersion(this.versionObject)
        .then(
          (res) => {
            this.getVersions(this.from, this.numberPerPage)
            Toastify({
              text: 'Version has been updated',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          },
          (err) => {
            let error = err
            if (err.response.data.description){
              error = err.response.data.description
            }
            Toastify({
              text: error,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          }
        )
    }
  }
}
</script>
