<template>
  <div class="footer-container flex mt-10">
    <div class="logo-container">
      <img
        src="../../assets/images/t1c-logo.png"
        alt="T1C Logo"
        class="t1c-logo"
      >
    </div>
    <div class="description text-center px-5">
      <p>{{ $t("overview.serviceAgreement") }}</p>
      <p class="text-center">
        Copyright © {{ year }} - TRUST1TEAM - {{ $t("overview.rightsReserved") }}.
      </p>
    </div>
    <div class="language-container">
      <a
        href=""
        class="clickable"
        :class="activeLang === 'fr' ? 'active' : ''"
        @click.prevent="switchLanguage('fr'), activeLang = 'fr'"
      > FR
      </a>
      <a
        href=""
        class="clickable"
        :class="activeLang === 'en' ? 'active' : ''"
        @click.prevent="switchLanguage('en'), activeLang = 'en'"
      > EN
      </a>
      <a
        href=""
        class="clickable"
        :class="activeLang === 'nl' ? 'active' : ''"
        @click.prevent="switchLanguage('nl'), activeLang = 'nl'"
      > NL
      </a>
      <!-- <span><i class="fas fa-question-circle" /></span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      activeLang: null,
      year: new Date().getFullYear()
    }
  },
  created() {
    this.activeLang = this.$i18n.locale
  },
  methods: {
    switchLanguage(lang) {
      this.$i18n.locale = lang
      window.VUE_APP_ENV_DEFAULT_LANGUAGE = lang
    }
  }
}
</script>

<style scoped>
.footer-container {
  color: white;
  width: 100%;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.t1c-logo {
  width: 40px;
}

.description {
  margin-left: 20px;
  font-size: 14px;
}

.language-container span {
  margin-right: 5px;
}

.clickable {
  padding-left: 4px;
  cursor: pointer;
}

.active {
  font-weight: 700;
}
</style>
