<template>
  <div class="summary">
    <div class="grid grid-cols-12 p-10">
      <div class="col-span-12 text-center t1c-color-text pt-1 pl-8 text-lg">
        <b>{{ count.devices }}</b> {{ $t("overview.Devices in") }} <b>{{ count.labels }}</b>
        {{ $t("overview.Applications and") }} <b>{{ count.organizations }}</b>
        {{ $t("overview.organizations") }}
      </div>
    </div>
    <div class="grid grid-cols-12 pl-10">
      <div class="col-span-12 md:col-span-6 grid grid-cols-12 p-5">
        <div class="col-span-4 font-medium text-xl ">
          <b>{{ $t("overview.Organization") }}:</b>
        </div>
        <div class="col-span-7 pl-3 grid grid-cols-3">
          <div class="col-span-3">
            <TomSelect
              v-model="chartOrganizationFilter"
              class="w-full"
              multiple
              @change="filterChartData, (chartLabelFilter = [])"
            >
              <option
                v-for="(organization, index) in fetchedOrganizations"
                :key="index"
                :value="organization.id"
              >
                {{ organization.name }}
              </option>
            </TomSelect>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-6 grid grid-cols-12 p-5">
        <div class="col-span-4 font-medium text-xl ">
          <b>{{ $t("overview.Labels") }}:</b>
        </div>
        <div class="col-span-7 pl-3">
          <TomSelect v-model="chartLabelFilter" class="w-full" multiple @change="filterChartData">
            <option v-for="(label, index) in filteredChartLabels" :key="index" :value="label.id">
              {{ label.name }}
            </option>
          </TomSelect>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-10 my-5 py-5 px-5">
      <div class="col-span-10 md:col-span-5 lg:col-span-3 xl:col-span-2 px-3 m-2">
        <div class="relative">
          <DonutChart
            :labels-array="browserLabels"
            :values="browserData"
            :height="30"
            :width="30"
          />
          <div
            class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0 internal-label"
          >
            <div class="text-md font-medium text-right">
              <b>{{ $t("overview.Browser") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-10 md:col-span-5 lg:col-span-3 xl:col-span-2 px-3 m-2">
        <div class="relative">
          <DonutChart :labels-array="osLabels" :values="osData" :height="30" :width="30" />
          <div
            class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0 internal-label"
          >
            <div class="text-md font-medium text-right">
              <p>
                <b>{{ $t("overview.Operating") }}</b>
              </p>
              <p class="text-center">
                <b>{{ $t("overview.System") }}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-10 md:col-span-5 lg:col-span-3 xl:col-span-2 px-3 m-2">
        <div class="relative">
          <DonutChart :labels-array="t1cLabels" :values="t1cData" :height="30" :width="30" />
          <div
            class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0 internal-label"
          >
            <div class="text-md font-medium text-right">
              <b>{{ $t("overview.T1C Version") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-10 md:col-span-5 lg:col-span-3 xl:col-span-2 px-3 m-2">
        <div class="relative">
          <DonutChart
            :labels-array="registryLabels"
            :values="registryData"
            :height="30"
            :width="30"
          />
          <div
            class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0 internal-label"
          >
            <div class="text-md font-medium text-right">
              <b>{{ $t("overview.Registry") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-10 md:col-span-5 lg:col-span-3 xl:col-span-2 px-3 m-2">
        <div class="relative">
          <DonutChart :labels-array="labelLabels" :values="labelData" :height="30" :width="30" />
          <div
            class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0 internal-label"
          >
            <div class="text-md font-medium text-right">
              <b>{{ $t("overview.Labels") }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 p-5 mt-10 pt-8 transactions">
      <div class="col-span-11 text-right italic pr-20 mt-5">
        <span class="export" @click="exportCSV()">
          <i class="fas fa-file-csv fa-2x" color="grey" />&nbsp; {{ $t("overview.Export") }}
        </span>
      </div>
      <div class="col-span-12 grid grid-cols-12 mt-5">
        <div class="col-span-12 lg:col-span-6 grid grid-cols-12">
          <div class="col-span-4 font-medium text-xl ">
            <b>{{ $t("overview.Organization") }}:</b>
          </div>
          <div class="col-span-6">
            <TomSelect
              v-model="organizationFilter"
              class="w-full"
              multiple
              @change="getTransactionData"
            >
              <option
                v-for="(organization, index) in fetchedOrganizations"
                :key="index"
                :value="organization.id"
              >
                {{ organization.name }}
              </option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 grid grid-cols-12  mt-5 lg:mt-0">
          <div class="col-span-4 font-medium text-xl ">
            <b>{{ $t("overview.Labels") }}:</b>
          </div>
          <div class="col-span-6">
            <TomSelect v-model="labelFilter" class="w-full" multiple @change="getTransactionData">
              <option v-for="(label, index) in filteredLabels" :key="index" :value="label.id">
                {{ label.name }}
              </option>
            </TomSelect>
          </div>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 mt-5">
        <div class="col-span-12 lg:col-span-6 grid grid-cols-12">
          <div class="col-span-4 pt-2">
            <b>{{ $t("overview.From") }}: &ensp;</b>
          </div>
          <div class="col-span-6">
            <input
              v-model="filterTransactionFrom"
              type="date"
              class="form-input intro-x login__input form-control border-gray-300"
              :max="filterTransactionTo"
              @change="onDateChange"
            />
          </div>
          <div
            v-if="filterTransactionFrom"
            class="col-span-1 pl-2 pt-2 delete-date"
            @click="(filterTransactionFrom = null), getTransactionData()"
          >
            <i class="fas fa-times-circle" />
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 grid grid-cols-12 mt-5 lg:mt-0">
          <div class="col-span-4 pt-2">
            <b>{{ $t("overview.To") }}:</b>
          </div>
          <div class="col-span-6">
            <input
              v-model="filterTransactionTo"
              type="date"
              class="form-input intro-x login__input form-control border-gray-300"
              :min="filterTransactionFrom"
              @change="onDateChange"
            />
          </div>
          <div
            v-if="filterTransactionTo"
            class="col-span-1 pl-2 pt-2 delete-date"
            @click="(filterTransactionTo = null), getTransactionData()"
          >
            <i class="fas fa-times-circle" />
          </div>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 justify-items-center py-10">
        <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="far fa-id-badge fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("READ") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Readings") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-user-check fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("AUTHENTICATE") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Authentications") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-file-signature fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("SIGN") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Signatures") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
        <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-file-contract fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("VERIFY_PIN") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Verifications") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 justify-items-center py-10">
        <div class="col-span-6 md:col-span-4 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-download fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("DOWNLOAD") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Files download") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-cloud-upload-alt fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("UPLOAD") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Files upload") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
        <div class="col-span-6 md:col-span-4 case-transactions text-center my-3">
          <span class="fa-stack mt-2">
            <i class="fa fa-circle fa-stack-2x icon-background" />
            <i class="fas fa-print fa-stack-1x" color="white" />
          </span>
          <div class="text-xl font-extrabold t1c-color-text">
            {{ transactionNumber("PRINT") }}
          </div>
          <div class="text-base font-medium">
            {{ $t("overview.Printing files") }}
          </div>
          <div
            v-if="filterTransactionFrom || filterTransactionTo"
            class="text-gray-400 text-xs italic"
          >
            <span v-if="filterTransactionFrom && filterTransactionTo">
              {{ filterTransactionFrom + " - " + filterTransactionTo }}
            </span>
            <span v-else-if="filterTransactionFrom">
              {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
            </span>
            <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
          </div>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 justify-items-center py-10">
        <div class="col-span-12 case-transactions case-last text-center">
          <div class="flex">
            <span class="fa-stack mt-2">
              <i class="fa fa-circle fa-stack-2x icon-background" />
              <i class="fas fa-calculator fa-stack-1x" color="white" />
            </span>
            <div class="self-center">
              <div class="flex self-center">
                <div class="text-base font-medium ml-3 mt-1 self-center">
                  {{ $t("overview.Total transactions") }}: &nbsp;
                </div>
                <div class="text-xl font-extrabold t1c-color-text self-center">
                  {{ transactionNumber() }}
                </div>
              </div>
              <div
                v-if="filterTransactionFrom || filterTransactionTo"
                class="text-gray-400 text-xs italic"
              >
                <span v-if="filterTransactionFrom && filterTransactionTo">
                  {{ filterTransactionFrom + " - " + filterTransactionTo }}
                </span>
                <span v-else-if="filterTransactionFrom">
                  {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
                </span>
                <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dnsRebinds" class="col-span-12">
        <div class="font-medium text-xl my-10 col-span-12">
          <b>{{ $t("overview.dnsRebinds") }}</b>
        </div>
        <div v-if="dnsRebinds && isSysAdmin" class="col-span-12 mb-10">
          <div class="col-span-12 text-center t1c-color-text pt-1 pl-8 text-lg">
            <b>{{ dnsRebinds.deviceTotal }}</b> {{ $t("overview.totalDevicesWith") }}
            <b> {{ dnsRebinds.total }}</b> {{ $t("overview.devicesWithIssue") }}
            <b>{{ dnsRebinds.resolved }}</b> {{ $t("overview.resolved") }}
            <b>{{ dnsRebinds.unresolved }}</b> {{ $t("overview.unresolved") }}
          </div>
        </div>
        <div class="col-span-12 grid grid-cols-12 justify-items-center pb-10">
          <div class="col-span-12 md:col-span-6 lg:col-span-3  case-transactions text-center my-3">
            <DataBox
              :title="$t('overview.devicesWithDnsIssue')"
              icon-class="fas fa-desktop"
              :value="dnsRebinds.percentageOfDevices"
              :filter-transaction-from="filterTransactionFrom"
              :filter-transaction-to="filterTransactionTo"
            />
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
            <DataBox
              :title="$t('overview.resolvedDevices')"
              icon-class="fas fa-check"
              :value="dnsRebinds.percentageOfResolvedDevices"
              :filter-transaction-from="filterTransactionFrom"
              :filter-transaction-to="filterTransactionTo"
            />
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
            <DataBox
              :title="$t('overview.unresolvedDevices')"
              icon-class="fas fa-times"
              :value="dnsRebinds.percentageOfUnresolvedDevices"
              :filter-transaction-from="filterTransactionFrom"
              :filter-transaction-to="filterTransactionTo"
            />
          </div>
          <div class="col-span-12 md:col-span-6 lg:col-span-3 case-transactions text-center my-3">
            <DataBox
              :title="$t('overview.resolutionPercentage')"
              icon-class="fas fa-check-double"
              :value="dnsRebinds.percentageOfResolutions"
              :filter-transaction-from="filterTransactionFrom"
              :filter-transaction-to="filterTransactionTo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DonutChart from "./components/donut-chart.vue";
import OverviewService from "@/services/OverviewService";
import AdministrationService from "@/services/AdministrationService";
import DataBox from "./components/data-box.vue";
export default {
  name: "Summary",
  components: {
    DonutChart,
    DataBox,
    DataBox,
  },
  data() {
    return {
      count: {},
      breakdown: {},
      summary: {},
      dnsRebinds: null,
      chartOrganizationFilter: [],
      chartLabelFilter: [],
      fetchedOrganizations: [],
      fetchedLabels: [],
      filterTransactionFrom: null,
      filterTransactionTo: null,
      organizationFilter: [],
      labelFilter: [],
      filteredData: null,
      browserLabels: null,
      browserData: null,
      osLabels: null,
      osData: null,
      t1cLabels: null,
      t1cData: null,
      registryLabels: null,
      registryData: null,
      labelLabels: null,
      labelData: null,
    };
  },
  computed: {
    filteredLabels() {
      if (this.organizationFilter.length > 0) {
        return this.fetchedLabels.filter((label) => this.organizationFilter.includes(label.orgId));
      } else {
        return this.fetchedLabels;
      }
    },
    filteredChartLabels() {
      if (this.chartOrganizationFilter.length > 0) {
        return this.fetchedLabels.filter((label) =>
          this.chartOrganizationFilter.includes(label.orgId)
        );
      } else {
        return this.fetchedLabels;
      }
    },
    isSysAdmin() {
      return this.$store.state.user.roles.includes("SysAdmin");
    },
  },
  mounted() {
    this.getCount();
    this.getDeviceBreakdown();
    this.getTransactionData();
    this.getDnsRebinds();
    AdministrationService.getOrganizations().then((response) => {
      this.fetchedOrganizations = response.data.data.results;
    });

    AdministrationService.getLabels().then((response) => {
      this.fetchedLabels = response.data.data.results;
    });
  },
  methods: {
    getCount() {
      OverviewService.getCount(this.chartOrganizationFilter, this.chartLabelFilter)
        .then((response) => {
          this.count = response.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDeviceBreakdown() {
      OverviewService.getDeviceBreakdown(this.chartOrganizationFilter, this.chartLabelFilter)
        .then((response) => {
          this.breakdown = response.data.data;
          if (this.breakdown) {
            if (this.breakdown.agent) {
              this.browserLabels = Object.keys(this.breakdown.agent);
              this.browserData = Object.values(this.breakdown.agent);
            }
            if (this.breakdown.os) {
              this.osLabels = Object.keys(this.breakdown.os);
              let unknownIndex = this.osLabels.findIndex((os) => os === "_unknown_");
              if (unknownIndex !== -1) {
                this.osLabels[unknownIndex] = "Unknown";
              }
              this.osData = Object.values(this.breakdown.os);
            }
            if (this.breakdown.version) {
              this.t1cLabels = Object.keys(this.breakdown.version);
              this.t1cData = Object.values(this.breakdown.version);
            }
            if (this.breakdown.sharedEnvironment) {
              this.registryLabels = Object.keys(this.breakdown.sharedEnvironment);
              this.registryData = Object.values(this.breakdown.sharedEnvironment);
            }
            if (this.breakdown.label) {
              this.labelLabels = Object.keys(this.breakdown.label);
              this.labelData = Object.values(this.breakdown.label);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTransactionReport() {
      OverviewService.getTransactionReport().then((response) => {
        this.summary = response.data.data.summary;
      });
    },
    transactionNumber(useCase) {
      if (this.summary && this.summary.report) {
        if (!useCase) {
          if (this.summary.total) {
            return this.summary.total;
          } else {
            return 0;
          }
        } else {
          if (this.summary.report.find((element) => element.useCase === useCase)) {
            return this.summary.report.find((element) => element.useCase === useCase).total;
          } else {
            return 0;
          }
        }
      } else {
        return "loading...";
      }
    },
    getTransactionData() {
      OverviewService.getTransactionReport(
        this.organizationFilter,
        this.labelFilter,
        this.filterTransactionFrom,
        this.filterTransactionTo
      ).then((response) => {
        this.summary = response.data.data.summary;
      });
    },
    getDnsRebinds() {
      OverviewService.getDnsRebinds(this.filterTransactionFrom, this.filterTransactionTo).then(
        (response) => {
          this.dnsRebinds = response.data.data;
        }
      );
    },
    onDateChange() {
      this.getTransactionData();
      this.getDnsRebinds();
    },
    filterChartData() {
      this.getCount();
      this.getDeviceBreakdown();
    },
    exportCSV() {
      AdministrationService.getTransactionsCSV(
        "USE_CASE",
        this.filterTransactionFrom,
        this.filterTransactionTo
      ).then((response) => {
        var filename = "";
        var disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        const anchor = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(response.data);
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
  },
};
</script>
