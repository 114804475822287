<template>
  <div class="devices">
    <div class="grid grid-cols-12">
      <div class="col-span-4 pl-8 pt-5 font-medium text-3xl">
        <b>{{ $t("overview.Find device by ID") }}</b>
      </div>
      <div class="col-span-12 flex flex-col md:flex-row my-10">
        <div class="md:ml-10 pt-2">
          <b>{{ $t("overview.Device ID") }}:</b>
        </div>
        <input
          v-model="deviceID"
          type="text"
          class="form-input intro-x login__input form-control border-gray-300 md:mx-5 mt-5 md:mt-0 !w-[550px]"
        />
        <button
          class="btn add-button text-white md:ml-10 mt-5 md:mt-0 intro-x"
          @click="searchDevice()"
        >
          {{ $t("overview.Search") }}
        </button>
      </div>
      <div class="col-span-12 flex md:ml-16 grid grid-cols-span-6 md:grid-cols-12 mb-10">
        <div class="col-span-5 md:mr-5">
          <span class="font-bold">ID:</span>
          <div class="intro-x box mt-4 py-3 px-4 overflow-x-auto h-12">
            {{ device.id }}
          </div>
        </div>
        <div class="col-span-5 md:ml-5">
          <span class="font-bold">{{ $t("overview.First install") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            <span v-if="device.firstInstall">{{
              moment(device.firstInstall)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
        <div class="col-span-5 mt-5 md:mr-5">
          <span class="font-bold">{{ $t("overview.T1C Version") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            {{ device.t1cVersion }}
          </div>
        </div>
        <div class="col-span-5 mt-5 md:ml-5">
          <span class="font-bold">{{ $t("administration.Status") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            {{ device.status }}
          </div>
        </div>
        <div class="col-span-5 mt-5 md:mr-5">
          <span class="font-bold">{{ $t("overview.User") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            {{ device.ctxUser }}
          </div>
        </div>
        <div class="col-span-5 mt-5 md:ml-5">
          <span class="font-bold">{{ $t("overview.Last action") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            <span v-if="device.lastAction">{{
              moment(device.lastAction)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
        <div class="col-span-5 mt-5 md:mr-5">
          <span class="font-bold">{{ $t("administration.Updated on") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            <span v-if="device.updatedOn">{{
              moment(device.updatedOn)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
        <div class="col-span-5 mt-5 md:ml-5">
          <span class="font-bold">{{ $t("overview.Certificate") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 overflow-x-auto h-12">
            {{ device.certificate }}
          </div>
        </div>
        <div v-if="device.dnsRebindIssueOccurrence" class="col-span-5 mt-5 md:mr-5">
          <span class="font-bold">{{ $t("administration.dnsRebindIssueOccurrence") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            <span>{{
              moment(device.dnsRebindIssueOccurrence)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
        <div v-if="device.dnsRebindIssueOccurrence" class="col-span-5 mt-5 md:ml-5">
          <span class="font-bold">{{ $t("administration.dnsRebindIssueResolution") }}:</span>
          <div class="intro-x box mt-4 py-3 px-4 h-12">
            <span v-if="device.dnsRebindIssueResolution">{{
              moment(device.dnsRebindIssueResolution)
                .tz(moment.tz.guess())
                .format("YYYY-MM-DD HH:mm:ss")
            }}</span>
            <span v-else>{{ $t("overview.unresolvedYet") }}</span>
          </div>
        </div>
      </div>
      <div v-if="logsList" class="col-span-4 pl-8 pt-5 font-medium text-3xl">
        <b>{{ $t("overview.logs") }}</b>
      </div>
      <div v-if="logsList" class="col-span-12 grid grid-cols-1 w-full">
        <div v-if="logsList.length === 0" class="mt-6 pl-8">
          {{ $t("overview.logsEmpty") }}
        </div>
        <div>
          <div
            v-if="logsList.length > 0"
            class="md:float-left md:w-[190px] box mt-4 py-3 px-4 md:mr-3 md:h-[750px]"
          >
            <div class="font-medium text-lg mb-3">
              <b>{{ $t("overview.timestamp") }}</b>
            </div>
            <div v-for="(item, index) in logsList" :key="index" class="my-2">
              <div class="flex justify-between no-wrap">
                <div
                  class="cursor-pointer"
                  :class="selectedTimestamp?.timestamp === item.timestamp ? 't1c-color-text' : ''"
                  @click="selectTimestamp(item)"
                >
                  {{
                    moment(item.timestamp)
                      .tz(moment.tz.guess())
                      .format("YYYY-MM-DD HH:mm:ss")
                  }}
                </div>
                <div class="cursor-pointer" @click="downloadTimestamp(item)">
                  <i class="fas fa-download" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="logsList.length > 0"
            class="md:float-right md:w-[calc(100%-205px)] h-[750px] box mt-4 col-span-12"
          >
            <div v-if="selectedTimestamp" class="flex items-center">
              <div
                v-for="(item, index) in selectedTimestamp.filename"
                :key="index"
                class="cursor-pointer w-full text-center py-3 border-r last:border-0 first:rounded-tl last:rounded-tr"
                :class="item === selectedLog ? 'text-white t1c-color-background' : ''"
                @click="selectLog(item)"
              >
                {{ item }}
              </div>
            </div>
            <div class="border-t h-[700px] p-5 w-full">
              <div v-if="loadingLogDetails" class="p-3">
                <i class="fas fa-circle-notch fa-spin" />
              </div>
              <div v-if="selectedLog && !loadingLogDetails" class="flex justify-end">
                <span class="cursor-pointer" @click="downloadLog"
                  ><i class="fas fa-download"
                /></span>
              </div>
              <div class="content-file max-h-[95%] overflow-auto mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toastify from "toastify-js";
import OverviewService from "@/services/OverviewService";
import moment from "moment-timezone";

export default {
  name: "Devices",
  data() {
    return {
      deviceID: "",
      device: {},
      logsList: null,
      selectedLog: null,
      selectedTimestamp: null,
      loadingLogDetails: false,
    };
  },
  created() {
    this.moment = moment;
  },
  methods: {
    async searchDevice() {
      this.logsList = null;
      this.selectedTimestamp = null;
      this.selectedLog = null;
      try {
        const response = await OverviewService.getDevice(this.deviceID);
        this.device = response.data.data;

        const statusResponse = await OverviewService.getLogServiceStatus();
        if (statusResponse?.status === 200) {
          const logsResponse = await OverviewService.getLogs(this.deviceID);
          this.logsList = logsResponse.data.data;
        }
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.description) {
          error = err.response.data.description;
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          className: "toastify-content",
        }).showToast();
      }
    },
    async selectLog(item) {
      try {
        const content = document.querySelector(".content-file");
        content.innerText = null;
        this.selectedLog = item;
        this.loadingLogDetails = true;
        const logDetailsResponse = await OverviewService.downloadLogs(
          this.deviceID,
          item,
          this.selectedTimestamp.timestamp,
          true
        );
        this.loadingLogDetails = false;
        content.innerText = logDetailsResponse.data;
      } catch (err) {
        this.loadingLogDetails = false;
        let error = err;
        if (err.response && err.response.data && err.response.data.description) {
          error = err.response.data.description;
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          className: "toastify-content",
        }).showToast();
      }
    },
    selectTimestamp(item) {
      this.selectedTimestamp = item;
      this.selectedLog = null;
      const content = document.querySelector(".content-file");
      content.innerText = null;
      if (item.filename?.length > 0) {
        this.selectLog(item.filename[0]);
      }
    },
    async downloadTimestamp(item) {
      try {
        const response = await OverviewService.downloadTimestamp(this.deviceID, item.timestamp);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        var filename = "";
        var disposition = response.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        link.setAttribute("download", filename + ".zip");
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.description) {
          error = err.response.data.description;
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          className: "toastify-content",
        }).showToast();
      }
    },
    async downloadLog(item) {
      try {
        const logDetailsResponse = await OverviewService.downloadLogs(
          this.deviceID,
          this.selectedLog,
          this.selectedTimestamp.timestamp,
          false
        );
        var filename = "";
        var disposition = logDetailsResponse.headers["content-disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        const anchor = document.createElement("a");
        anchor.href = "data:text/txt;charset=utf-8," + encodeURIComponent(logDetailsResponse.data);
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      } catch (err) {
        this.loadingLogDetails = false;
        let error = err;
        if (err.response && err.response.data && err.response.data.description) {
          error = err.response.data.description;
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true,
          className: "toastify-content",
        }).showToast();
      }
    },
  },
};
</script>

<style scoped>
.device-card {
  width: 30%;
  margin: 25px;
}
</style>
