<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex justify-between p-4">
        <div class="font-medium text-3xl">
          <b>{{ $t("administration.ATR List") }}</b>
        </div>
      </div>
      <div class="col-span-12 placeholder" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AtrList'
}
</script>

<style scoped>
.organizations-container {
  height: 600px
}
</style>
