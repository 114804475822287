<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex justify-between p-4">
        <div class="font-medium text-3xl">
          <b>{{ $t("overview.Tags") }}</b>
        </div>
        <div
          class="export"
          @click="exportCSV()"
        >
          <i
            class="fas fa-file-csv fa-2x"
            color="grey"
          />
        </div>
      </div>
      <div class="col-span-6 lg:col-span-4 grid grid-cols-12 px-5 pt-5 height-test">
        <div class="col-span-4 text-lg pt-2">
          {{ $t("overview.Organization") }}:
        </div>
        <div class="col-span-6 pl-3 grid grid-cols-3">
          <div class="col-span-3">
            <TomSelect
              v-model="organizationFilter"
              class="w-full"
              multiple
              @change="fetchLabels"
            >
              <option
                v-for="organisation in organisations"
                :key="organisation"
                :value="organisation.id"
              >
                {{ organisation.name }}
              </option>
            </TomSelect>
          </div>
        </div>
      </div>
      <div class="col-span-6 lg:col-span-4 grid grid-cols-12 p-5 height-test">
        <div class="col-span-3 text-lg pt-2">
          {{ $t("overview.Labels") }}:
        </div>
        <div class="col-span-6 pl-3">
          <TomSelect
            v-model="labelFilter"
            class="w-full"
            multiple
          >
            <option
              v-for="label in labels"
              :key="label"
              :value="label.id"
            >
              {{ label.name }} | {{ label.orgId }}
            </option>
          </TomSelect>
        </div>
      </div>
      <div class="col-span-12 text-right my-5">
        <button
          class="btn btn-large apply-button"
          @click="filterData"
        >
          {{ $t("overview.Apply") }}
        </button>
        <button
          class="btn btn-large clear-button text-white"
          @click="clearFilterData"
        >
          {{ $t("overview.Clear") }}
        </button>
      </div>
    </div>
    <div class="col-span-12">
      <div class="overflow-x-auto">
        <table class="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                {{ $t("overview.Tags") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                {{ $t("overview.Organization") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                {{ $t("overview.Labels") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                {{ $t("overview.useCase") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                {{ $t("overview.Total transactions") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="row in tagsToDisplay"
              :key="row"
            >
              <td class="border-b whitespace-nowrap text-center">
                {{ row.tag === '_unknown_' ? 'Untagged' : row.tag }}
              </td>
              <td class="border-b whitespace-nowrap text-center">
                {{ row.orgId }}
              </td>
              <td class="border-b whitespace-nowrap text-center">
                {{ row.labelId }}
              </td>
              <td class="border-b whitespace-nowrap text-center">
                {{ row.useCase }}
              </td>
              <td class="border-b whitespace-nowrap text-center">
                {{ row.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AdministrationService from "@/services/AdministrationService";
import Toastify from "toastify-js";

export default {
  name: 'Tags',
  data(){
    return {
      organisations: [],
      labels: [],
      tags: [],

      tagFilter: [],
      labelFilter: [],
      organizationFilter: [],
      filteredTags: null
      
    }
  },
  computed: {
    labelsToDisplay(){
      if(this.organizationFilter.length > 0) {
        return this.labels.filter(label => this.organizationFilter.includes(label.orgId))
      } else {
        return this.labels
      }
    },
    tagsToDisplay() {
      if(this.filteredTags) {
        return this.filteredTags
      } else {
        return this.tags
      }
    }
  },
  created() {
    this.fetchLabels()
    this.fetchOrganizations()
    this.getContent(this.from, this.pageSize)
  },
  methods: {
    filterData(){
      if(this.organizationFilter.length > 0 || this.labelFilter.length > 0){
        if(this.organizationFilter.length > 0){
          this.filteredTags = this.tags.filter(tag => this.organizationFilter.includes(tag.orgId))
        }
        if(this.labelFilter.length > 0){
          this.filteredTags = this.tags.filter(tag => this.labelFilter.includes(tag.labelId))
        }
      } else {
        this.filteredTags = null
      }
    },
    fetchOrganizations() {
      AdministrationService.getOrganizations()
          .then(response => {
            this.organisations = response.data.data.results
          }, err => {
            let error = 'Organizations could not be fetched'
            if (err.response.data.description){
              error = err.response.data.description
            }
            this.showToast(error)
          })
    },
    fetchLabels(){
      AdministrationService.getLabelsByOrganisations(this.organizationFilter)
          .then(response => {
            this.labels = response.data.data.results
          }, err => {
            let error = 'labels could not be fetched'
            if (err.response.data.description){
              error = err.response.data.description
            }
            this.showToast(error)
          })
    },
    clearFilterData(){
      this.tagFilter = []
      this.labelFilter = []
      this.organizationFilter = []
      this.filteredTags = null
    },
    getContent(from, size) {
      AdministrationService.getTransactionReportByLabelTagUseCase(from,size).then(res => {
        this.tags = res.data.data.summary.report
      }, err => {
        console.error(err)
        let error = 'data could not be fetched'
        if (err.response.data.description){
          error = err.response.data.description
        }
        this.showToast(error)
      })
    },
    exportCSV(){
      AdministrationService.getTransactionsCSV('LABEL_TAG_USE_CASE', null, null)
      .then((response) => {
        var filename = "";
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = filename;
        anchor.click();
      })
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  }
}
</script>
