<template>
  <div class="grid grid-cols-12 p-5">
    <div class="col-span-12 flex justify-between p-4">
      <div class="font-medium text-3xl">
        <b>Billing Details:</b>
      </div>
    </div>
    <div class="my-10 col-span-12 mx-5">
      <div>
        <table class="table">
          <thead>
            <tr>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap font-semibold text-lg">
                {{ $t("overview.Labels") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold text-lg">
                Tag
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold text-lg">
                {{ $t("overview.Total transactions") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold text-lg">
                {{ $t("overview.useCase") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center text-lg">
                {{ $t("administration.PeriodStart") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center text-lg">
                {{ $t("administration.PeriodEnd") }}
              </th>
              <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center text-lg">
                Module ID
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(label, index) in labels"
              :key="index"
            >
              <tr>
                <td class="border-b whitespace-nowrap t1c-color-text font-semibold text-lg">
                  &emsp;{{ label.charAt(0).toUpperCase() + label.slice(1) }}
                </td>
                <td class="border-b whitespace-nowrap text-center" />
                <td class="border-b whitespace-nowrap text-center t1c-color-text" />
                <td class="border-b whitespace-nowrap text-center" />
              </tr>
              <tr
                v-for="(transaction, transactionIndex) in transactionArchives"
                :key="transactionIndex"
              >
                <td class="border-b whitespace-nowrap text-center" />
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.tag === '_unknown_' ? 'Unknown' : transaction.tag }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.periodTotal }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.useCase }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.periodStart }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.periodEnd }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.moduleId }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-span-12 grid grid-cols-12 my-10 pl-12 ml-5">
      <div class="col-span-3 flex">
        <select
          v-model="numberPerPage"
          class="number-per-page"
          aria-label="Default select example"
          @change="currentPage > totalPages ? currentPage = totalPages : '', pageUpdate()"
        >
          <option value="5">
            5
          </option>
          <option value="10">
            10
          </option>
          <option value="15">
            15
          </option>
          <option value="20">
            20
          </option>
          <option value="50">
            50
          </option>
        </select>
        <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
      </div>
      <div class="col-span-4 text-right">
        <span
          class="pagination-most-left"
          @click="currentPage = 1, pageUpdate()"
        ><i class="fas fa-angle-double-left" /></span>
        <span
          class="pagination-left"
          @click="currentPage > 1 ? currentPage -= 1 : '', pageUpdate()"
        ><i class="fas fa-angle-left" /></span>
        <span class="current-page">{{ currentPage }} of {{ totalPages }}</span>
        <span
          class="pagination-right"
          @click="currentPage < totalPages ? currentPage += 1 : '', pageUpdate()"
        ><i class="fas fa-angle-right" /></span>
        <span
          class="pagination-most-right"
          @click="currentPage = totalPages, pageUpdate()"
        ><i class="fas fa-angle-double-right" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import AdministrationService from '@/services/AdministrationService'
export default {
  name: 'PastBillingDetail',
  data() {
    return {
      labels: [],
      transactionArchives: null,
      type: this.$route.params.type,
      invoiceId: this.$route.params.invoiceId,
      currentPage: 1,
      numberPerPage: 20,
      totalArchiveElements: null
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalArchiveElements/this.numberPerPage)
    },
  },
  created() {
    this.fetchLabelTransactionArchive()
  },
  methods: {
    archiveByLabels(labelId){
      if(this.transactionArchives){
        return this.transactionArchives.filter(transaction => transaction.labelId === labelId)
      } else {
        return []
      }
    },
    pageUpdate(){
      this.fetchLabelTransactionArchive(this.numberPerPage, this.currentPage, this.type, this.id)
    },
    fetchLabelTransactionArchive(){
      AdministrationService.getLabelTransactionArchive(this.numberPerPage, this.currentPage, 'invoice', this.invoiceId)
      .then(response => {
        this.totalArchiveElements = response.data.data.total
        this.transactionArchives = response.data.data.results
        if(this.type === 'organization') {
          let labelIds = []
          this.transactionArchives.forEach(transaction => {
            if(!labelIds.includes(transaction.labelId)){
              labelIds.push(transaction.labelId)
            }
          })
          this.labels = labelIds.sort(function(a, b) {
            var nameA = a.toUpperCase()
            var nameB = b.toUpperCase()
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        } else if (this.type === 'label') {
          this.labels = [this.id]
        }
      })
      .catch(err => console.log(err))
    }
  },
}
</script>
