<template>
  <div class="organizations-container p-5">
    <div class="grid grid-cols-12">
      <div class="col-span-12 flex p-4">
        <div class="font-medium text-xl">
          <b>SSL</b>
        </div>
      </div>
      <div class="col-span-12 grid grid-cols-12 flex p-4 pl-16">
        <div class="font-medium col-span-2 text-xl self-center">
          {{ $t("configuration.FileLocation") }}
        </div>
        <input
          v-model="file"
          type="text"
          class="form-input intro-x login__input form-control col-span-2 border-gray-300"
        >
      </div>
      <div class="col-span-12 grid grid-cols-12 flex p-4 pl-16">
        <div class="font-medium col-span-2 text-xl self-center">
          {{ $t("configuration.Password") }}
        </div>
        <input
          v-model="sslPassword"
          type="password"
          class="form-input intro-x login__input form-control col-span-2 border-gray-300"
        >
      </div>
      <div class="col-span-12">
        <button
          class="btn t1c-color-background text-white m-4 ml-16"
          @click="sslUpdate()"
        >
          {{ $t("configuration.Update") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationService from '@/services/ConfigurationService'
import Toastify from 'toastify-js'

export default {
  name: 'SSLConfiguration',
  data() {
    return {
      file: '',
      sslPassword: ''
    }
  },
  created() {
    this.getSSLConfig()
  },
  methods: {
    getSSLConfig() {
      ConfigurationService.getSsl().then(res => {
        this.file = res.data.data.config.uri
        this.sslPassword = res.data.data.config.password
      }, err => {
        let error = err
        if (err.response.data.description){
          error = err.response.data.description
        }
        Toastify({
          text: error,
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true,
          className: 'toastify-content'
        }).showToast()
      })
    },
    sslUpdate() {
      ConfigurationService.sslUpdate(this.file, this.sslPassword)
        .then(
          (res) => {
            Toastify({
              text: 'SSL context has been updated',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          },
          (err) => {
            let error = err
            if (err.response.data.description){
              error = err.response.data.description
            }
            Toastify({
              text: error,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'right',
              stopOnFocus: true,
              className: 'toastify-content'
            }).showToast()
          }
        )
    }
  }
}
</script>
