import axios from 'axios'
import store from '@/store'
import moment from 'moment';

const apiClient = axios.create({
    baseURL: window.VUE_APP_ENV_DS_URL
        ? window.VUE_APP_ENV_DS_URL
        : 'https://acc-ds.t1t.io',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
})

function getOrganizations() {
    return apiClient.get('/v3_5/organizations?from=0&size=40', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getLabels() {
    return apiClient.get('/v3_5/labels?from=0&size=40', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function createOrganization(name) {
    const data = {
        name: name
    }
    return apiClient.put('/v3_5/organizations', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function createUpdateLabel(label) {
    let data = {}
    if (label.id) {
        data.id = label.id
    }
    if (label.name) {
        data.name = label.name
    }
    if (label.orgId) {
        data.orgId = label.orgId
    }
    return apiClient.put('/v3_5/labels', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function deleteLabel(labelId) {
    return apiClient.delete(`/v3_5/labels/${labelId}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getCors() {
    return apiClient.get('/v3_5/cors', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function addCorsDomain(domain) {
    const data = {
        origin: domain
    }
    return apiClient.post('/v3_5/cors/allowed-origins', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function removeCorsDomain(id) {
    return apiClient.delete(`/v3_5/cors/allowed-origins/${id}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getLabelTagTransactionReport() {
    const data = {
        filter: {
            from: 0,
            size: 0
        },
        breakDownBy: "LABEL_TAG"
}
    return apiClient.post('/v3_5/transactions/report', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getTransactionsCSV(breakDownBy, periodStart, periodEnd) {
    const data = {
        filter: {
            from: 0,
            size: 0,
            period: {
                start: periodStart? moment(periodStart).utc().format(): null,
                end: periodEnd? moment(periodEnd).utc().format(): null
            }
        },
        breakDownBy: breakDownBy,
        csv : true
}
    return apiClient.post('/v3_5/transactions/report', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function periodTransactionReport(periodStart, periodEnd) {
    const data = {
        filter: {
            from: 0,
            size: 0,
            period: {
                start: periodStart? moment(periodStart).utc().format(): null,
                end: periodEnd? moment(periodEnd).utc().format(): null
            }
        },
        breakDownBy: "LABEL_TAG"
}
    return apiClient.post('/v3_5/transactions/report', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function regenerateApiKey(labelId) {
    return apiClient.post(`/v3_5/labels/${labelId}/key/regenerate`, null, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getLabelsByOrganisations(organisations) {
    let params
    if (organisations) {
        params = organisations.map((v) => {
            return `orgId=${v}&`
        })
    }
    if (params) {
        return apiClient.get(`/v3_5/labels?from=0&size=100&${params.join('')}`, {
            headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
        })
    } else {
        return apiClient.get(`/v3_5/labels?from=0&size=100`, {
            headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
        })
    }
}

function getTransactionReportByLabelTagUseCase(from, size, organizationFilter, labelFilter) {
    let data = {
        "filter": {
            "from": from,
            "size": size
        },
        "breakDownBy": "LABEL_TAG_USE_CASE"
    }
    return apiClient.post('/v3_5/transactions/report', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getLabelTransactionArchive(size, from, type, id) {
    if (type === 'label') {
        return apiClient.get(`/v3_5/transactions/archive?labelId=${id}&size=${size}&from=${(from - 1) * size}`, {
            headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
        })
    } else if (type === 'organization') {
        return apiClient.get(`/v3_5/transactions/archive?orgId=${id}&size=${size}&from=${(from - 1) * size}`, {
            headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
        })
    } else if (type === 'invoice') {
        return apiClient.get(`/v3_5/transactions/archive?invoice=${id}&size=${size}&from=${(from - 1) * size}`, {
            headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
        })
    }
}

function getLabelTagTransactionSummaries(size, from, orgId){
    return apiClient.get(`/v3_5/transactions/labels/summary?orgId=${orgId}&size=${size}&from=${(from - 1) * size}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function archiveTransactions(org, label) {
    const data = {
        orgId: org,
        label: label
    }
    return apiClient.post('/v3_5/transactions/archive', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getInvoices(size, from, orgId) {
    return apiClient.get(`/v3_5/transactions/invoicing?orgId=${orgId}&size=${size}&from=${(from - 1) * size}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getInvoiceItems(invoiceId, boolean) {
    return apiClient.get(`/v3_5/transactions/invoicing/${invoiceId}/items?csv=${boolean}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function exportCors() {
    return apiClient.get(`/v3_5/cors?csv=true`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}
    

export default {
    getOrganizations,
    getLabels,
    createOrganization,
    createUpdateLabel,
    deleteLabel,
    getCors,
    addCorsDomain,
    removeCorsDomain,
    getLabelTagTransactionReport,
    getTransactionsCSV,
    periodTransactionReport,
    regenerateApiKey,
    getLabelsByOrganisations,
    getTransactionReportByLabelTagUseCase,
    getLabelTransactionArchive,
    archiveTransactions,
    getInvoices,
    getLabelTagTransactionSummaries,
    getInvoiceItems,
    exportCors
}
