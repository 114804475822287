<template>
  <div>
    <span class="fa-stack mt-2">
      <i class="fa fa-circle fa-stack-2x icon-background" />
      <i class="fa-stack-1x" :class="iconClass" color="white" />
    </span>
    <div class="text-xl font-extrabold t1c-color-text">
      {{
        (value && value >= 0.01) || value === 0
          ? `${Math.round((value * 100 + Number.EPSILON) * 100) / 100}`
          : ""
      }}
      {{ value && value < 0.01 ? `< 1` : "" }}
      %
    </div>
    <div class="text-base font-medium">
      {{ title }}
    </div>
    <div v-if="filterTransactionFrom || filterTransactionTo" class="text-gray-400 text-xs italic">
      <span v-if="filterTransactionFrom && filterTransactionTo">
        {{ filterTransactionFrom + " - " + filterTransactionTo }}
      </span>
      <span v-else-if="filterTransactionFrom">
        {{ filterTransactionFrom }} - {{ $t("overview.Today") }}
      </span>
      <span v-else> {{ $t("overview.Indefinite") }} - {{ $t("overview.Today") }} </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null,
    },
    filterTransactionFrom: {
      type: String,
      default: null,
    },
    filterTransactionTo: {
      type: String,
      default: null,
    },
    iconClass: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>
