<template>
  <div class="col-span-12 grid grid-cols-12 p-5">
    <div class="col-span-12 flex justify-between p-4">
      <div class="font-medium text-3xl">
        <b>Billing:</b>
      </div>
    </div>
    <div class="col-span-12 p-10">
      <div class="pl-5 text-center text-xl">
        <b>{{ $t("administration.TurnInvoice") }}:
          <span class="t1c-color-text">{{ id }}</span></b>
      </div>

      <div class="text-center mt-5 pl-5">
        <button
          v-if="transactionSummaries.length > 0"
          class="btn btn-block t1c-color-border"
          @click="archiveTransactions(id, null)"
        >
          <i class="fas fa-file-invoice" /> &ensp; {{ $t("administration.TurnInvoice") }}
        </button>
      </div>

      <div class="text-center mt-5">
        {{ $t("administration.CreateBilling") }} <span class="font-semibold italic">{{ $t("administration.TurnInvoice") }}</span>
      </div>
      <div class="text-medium font-medium text-xl mt-10 ml-5">
        {{ $t("administration.NoInvoice") }}:
      </div>
      <div class="my-10 w-full">
        <div>
          <table class="table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("overview.Labels") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("administration.Tag") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("overview.Total transactions") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center" />
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("administration.PeriodStart") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("administration.PeriodEnd") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(transaction, index) in transactionSummaries"
                :key="index"
              >
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.label }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.tag === '_unknown_' ? 'Untagged' : transaction.tag }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ transaction.transactions }}
                </td>
                <td class="border-b whitespace-nowrap text-center t1c-color-text">
                  <button
                    class="btn btn-block t1c-color-border"
                    @click="archiveTransactions(null, transaction.label)"
                  >
                    <i class="fas fa-file-invoice" /> &ensp; {{ $t("administration.TurnInvoice") }}
                  </button>
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ moment(transaction.period.start).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ moment(transaction.period.end).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="transactionSummaries.length === 0"
            class="mt-5 text-center w-full"
            style="width: 100%"
          >
            No data to display
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 grid grid-cols-12 mb-5 pl-12 ml-5">
      <div class="col-span-3 flex">
        <select
          v-model="numberPerPage"
          class="number-per-page"
          aria-label="Default select example"
          @change="currentPage > totalPagesNonInvoiced ? currentPage = totalPagesNonInvoiced : '', pageUpdate()"
        >
          <option value="5">
            5
          </option>
          <option value="10">
            10
          </option>
          <option value="15">
            15
          </option>
          <option value="20">
            20
          </option>
          <option value="50">
            50
          </option>
        </select>
        <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
      </div>
      <div class="col-span-4 text-right pr-8">
        <span
          class="pagination-most-left"
          @click="currentPage = 1, pageUpdate()"
        ><i class="fas fa-angle-double-left" /></span>
        <span
          class="pagination-left"
          @click="currentPage > 1 ? currentPage -= 1 : '', pageUpdate()"
        ><i class="fas fa-angle-left" /></span>
        <span class="current-page">{{ currentPage }} of {{ totalPagesNonInvoiced }}</span>
        <span
          class="pagination-right"
          @click="currentPage < totalPagesNonInvoiced ? currentPage += 1 : '', pageUpdate()"
        ><i class="fas fa-angle-right" /></span>
        <span
          class="pagination-most-right"
          @click="currentPage = totalPagesNonInvoiced, pageUpdate()"
        ><i class="fas fa-angle-double-right" /></span>
      </div>
    </div>
    <div class="col-span-12 p-10 mt-10">
      <div class="text-medium font-medium text-xl mt-10 ml-5">
        {{ $t("administration.invoicedTransactions") }}:
      </div>
      <div class="my-10 w-full">
        <div>
          <table class="table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("overview.Labels") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("administration.Tag") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                  {{ $t("overview.Total transactions") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("administration.PeriodStart") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("administration.PeriodEnd") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                  {{ $t("administration.Status") }}
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center" />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(invoice, index) in invoices"
                :key="index"
              >
                <td class="border-b whitespace-nowrap text-center">
                  {{ invoice.label }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ invoice.tag === '_unknown_' ? 'Untagged' : invoice.tag }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ invoice.transactions }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ moment(invoice.period.start).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  {{ moment(invoice.period.end).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  <span v-if="invoice.status === 'CREATED'">{{ $t("overview.Created") }}</span>
                  <span v-else-if="invoice.status === 'PAID'"><i
                    class="fas fa-check-circle"
                    color="green"
                  />&ensp; {{ $t("administration.Paid") }}</span>
                </td>
                <td class="border-b whitespace-nowrap text-center">
                  <button
                    class="btn t1c-color-background text-white"
                    @click="openInvoice(invoice.id), invoiceId = invoice.id"
                  >
                    {{ $t("overview.Details") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="invoices.length === 0"
            class="mt-5 text-center w-full"
            style="width: 100%"
          >
            No data to display
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 grid grid-cols-12 my-5 pl-12 ml-5">
      <div class="col-span-3 flex">
        <select
          v-model="numberPerPageInvoiced"
          class="number-per-page"
          aria-label="Default select example"
          @change="currentPageInvoiced > totalPagesInvoiced ? currentPage = totalPagesInvoiced : '', invoicedPageUpdate()"
        >
          <option value="5">
            5
          </option>
          <option value="10">
            10
          </option>
          <option value="15">
            15
          </option>
          <option value="20">
            20
          </option>
          <option value="50">
            50
          </option>
        </select>
        <span class="ml-3 pt-1">{{ $t("overview.Per page") }}</span>
      </div>
      <div class="col-span-4 text-right pr-8">
        <span
          class="pagination-most-left"
          @click="currentPageInvoiced = 1, invoicedPageUpdate()"
        ><i class="fas fa-angle-double-left" /></span>
        <span
          class="pagination-left"
          @click="currentPageInvoiced > 1 ? currentPageInvoiced -= 1 : '', invoicedPageUpdate()"
        ><i class="fas fa-angle-left" /></span>
        <span class="current-page">{{ currentPageInvoiced }} of {{ totalPagesInvoiced }}</span>
        <span
          class="pagination-right"
          @click="currentPageInvoiced < totalPagesInvoiced ? currentPageInvoiced += 1 : '', invoicedPageUpdate()"
        ><i class="fas fa-angle-right" /></span>
        <span
          class="pagination-most-right"
          @click="currentPageInvoiced = totalPagesInvoiced, invoicedPageUpdate()"
        ><i class="fas fa-angle-double-right" /></span>
      </div>
    </div>
    <div
      id="invoice-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        style="width: 80%"
      >
        <div class="modal-content flex justify-center">
          <a
            data-dismiss="modal"
            href="javascript:;"
          >
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body w-full p-10">
            <div class="my-10 w-full">
              <div>
                <div class="col-span-11 text-right italic my-5">
                  <span
                    class="export"
                    @click="exportCSV()"
                  ><i
                    class="fas fa-file-csv fa-2x"
                    color="grey"
                  />
                  </span>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                        {{ $t("overview.Labels") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                        {{ $t("administration.Tag") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center font-semibold">
                        {{ $t("overview.Total transactions") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                        {{ $t("overview.useCase") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                        {{ $t("administration.Module") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                        {{ $t("administration.PeriodStart") }}
                      </th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap text-center">
                        {{ $t("administration.PeriodEnd") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in invoiceItems"
                      :key="index"
                    >
                      <td class="border-b whitespace-nowrap text-center">
                        {{ item.labelId }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ item.tag === '_unknown_' ? 'Untagged' : item.tag }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ item.periodTotal }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ item.useCase }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ item.moduleId }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ moment(item.periodStart).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                      </td>
                      <td class="border-b whitespace-nowrap text-center">
                        {{ moment(item.periodEnd).tz(moment.tz.guess()).format("YYYY-MM-DD") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="px-5 pt-4 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn add-button"
                @click="cancelDialog('invoice-modal')"
              >
                {{ $t("administration.Close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdministrationService from '@/services/AdministrationService'
import moment from 'moment-timezone'
import Toastify from 'toastify-js'
import store from '@/store/index.js'

export default {
  name: 'PastBillingDetail',
  beforeRouteEnter(routeTo, routeFrom, next) {
    if(store.state.user.roles.includes('SysAdmin')) {
      next(true)
    } else {
      next({ name: 'summary' })
    }
  },
  data() {
    return {
      labels: [],
      transactionArchives: null,
      type: this.$route.params.type,
      id: this.$route.params.orgId,
      currentPage: 1,
      numberPerPage: 10,
      transactionSummaries: [],
      transactionTotal: null,
      invoices: [],
      invoicesTotal: null,
      currentPageInvoiced: 1,
      numberPerPageInvoiced: 10,
      invoiceItems: [],
      invoiceId: null
    }
  },
  computed: {
    totalPagesNonInvoiced() {
      if(this.transactionTotal/this.numberPerPage === 0){
        return 1
      } else {
        return Math.ceil(this.transactionTotal/this.numberPerPage)
      }
    },
    totalPagesInvoiced() {
      if(this.invoices.length/this.numberPerPage === 0){
        return 1
      } else {
        return Math.ceil(this.invoices.length/this.numberPerPage)
      }
    },
  },
  created() {
    this.moment = moment
    this.openBilling()
  },
  methods: {
    openBilling(){
      AdministrationService.getLabelTagTransactionSummaries(this.numberPerPage, this.currentPage, this.id)
      .then(response => {
        this.transactionSummaries = response.data.data.results
        this.transactionTotal = response.data.data.total
        AdministrationService.getInvoices(this.numberPerPageInvoiced, this.currentPageInvoiced, this.id)
        .then(response => {
          this.invoices = response.data.data.results
          this.invoicesTotal = response.data.data.total
        })
      })
      .catch(err => this.showToast(err))
    },
    openInvoice(invoiceId){
      this.showDialog('invoice-modal')
      AdministrationService.getInvoiceItems(invoiceId, false)
      .then(response => {
        this.invoiceItems = response.data.data
      })
      .catch(err => this.showToast(err))
    },
    archiveTransactions(org, label) {
      AdministrationService.archiveTransactions(org,label).then(res => {
        this.openBilling()
        this.showToast("Archived successfully")
      }, err => {
        this.showToast(err)
      })
    },
    archiveByLabels(labelId){
      if(this.transactionArchives){
        return this.transactionArchives.filter(transaction => transaction.labelId === labelId)
      } else {
        return []
      }
    },
    pageUpdate(){
      AdministrationService.getLabelTagTransactionSummaries(this.numberPerPage, this.currentPage, this.id)
      .then(response => {
        this.transactionSummaries = response.data.data.results
      })
      .catch(err => this.showToast(err))
    },
    invoicedPageUpdate(){
      AdministrationService.getInvoices(this.numberPerPageInvoiced, this.currentPageInvoiced, this.id)
      .then(response => {
        this.invoices = response.data.data.results
        this.invoicesTotal = response.data.data.total
      })
    },
    showDialog(modalName) {
      cash('#' + modalName).modal('show')
    },
    cancelDialog(modalName) {
      this.invoiceId = null
      this.invoiceItems = []
      cash('#' + modalName).modal('hide')
    },
    exportCSV(){
      AdministrationService.getInvoiceItems(this.invoiceId, true)
      .then(response => {
        var filename = "";
        var disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) { 
              filename = matches[1].replace(/['"]/g, '');
            }
        }
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = filename;
        anchor.click();
      })
      .catch(err => {
        let error = err
        if (err.response.data.description){
          error = err.response.data.description
        }
        this.showToast(err)
      })
    },
    showToast(text) {
      Toastify({
        text: text,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
        className: 'toastify-content'
      }).showToast()
    }
  },
}
</script>
