import axios from 'axios'
import store from '@/store'

const apiClient = axios.create({
    baseURL: window.VUE_APP_ENV_DS_URL
        ? window.VUE_APP_ENV_DS_URL
        : 'https://acc-ds.t1t.io',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

function sslUpdate(file, password) {
    const data = {
        type: 'SSL_KEYSTORE',
        config: {
            uri: file,
            password: password
        }
    }
    return apiClient.put('/v3_5/configurations/central', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getSsl() {
    return apiClient.get('/v3_5/configurations/central/SSL_KEYSTORE', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getGeneralConfig() {
    return apiClient.get('/v3_5/configurations/central/GENERAL', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function setGeneralConfig(time) {
    const data = {
        type: 'GENERAL',
        config: {
            syncIntervalSeconds: time
        }
    }
    return apiClient.put('/v3_5/configurations/central', data, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getVersion(version) {
    return apiClient.get(`/v3_5/versions/${version}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function removeVersion(version) {
    return apiClient.delete(`/v3_5/versions/${version}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getVersions(from, size) {
    return apiClient.get(`/v3_5/versions?from=${from}&size=${size}`, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function updateVersion(request) {
    return apiClient.put('/v3_5/versions', request, {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}

function getLatestVersion() {
    return apiClient.get('/v3_5/versions/latest', {
        headers: {Authorization: `Bearer ${store.state.user.accessToken}`}
    })
}


export default {
    sslUpdate,
    getSsl,
    getGeneralConfig,
    setGeneralConfig,
    getVersion,
    removeVersion,
    getVersions,
    updateVersion,
    getLatestVersion
}
