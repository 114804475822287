export const Organizations = {
  namespaced: true,
  state: {
    organizations: [],
    selectedOrganization: null
  },
  mutations: {
    SET_ORGANIZATIONS(state, organizations) {
      state.organizations = organizations
    },
    SET_SELECTED_ORGANIZATION(state, organization) {
      state.organization = organization
    }
  },
  actions: {
    setOrganizations({ commit }, organizations) {
      commit('SET_ORGANIZATIONS', organizations)
    },
    setSelectedOrganization({ commit }, organization) {
      commit('SET_SELECTED_ORGANIZATION', organization)
    }
  },
  getters: {
    getSelectedOrganization: (state) => {
      return state.selectedOrganization
    },
    getOrganizations: (state) => {
      return state.organizations
    }
  }
}
